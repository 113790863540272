import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Moment from 'moment'
import _ from 'lodash'

import { Table, Select, Button, Space, notification, Descriptions, Row, Col, List, Modal, Typography } from 'antd';
import { InfoCircleOutlined, DoubleLeftOutlined, ExclamationCircleOutlined, DownloadOutlined, EditOutlined } from '@ant-design/icons';
// import Highlighter from 'react-highlight-words';
import { UploadOutlined, CheckOutlined, CloseOutlined, EditTwoTone, DeleteTwoTone } from '@ant-design/icons';

import languageManager from '../../../services/languageManager';

import * as storeActions from './store/actions/purchase_orders';

import Loader from '../../common/PageElements/Loader';
import PageHeader from '../../common/PageElements/PageHeader'

class PurchaseOrderDetails extends Component {
   state = {
      isCustomer: !_.isNil(this.props.authState.userData.account.parent_account_id),
      action: undefined,
      saveAndClose: false,
      pageLoader: true,
      loaderCancelOrder: false,
      orderData: {
         customer: {}
      },
      orderDetails: {},
      orderLines: [],
      componentLanguage: this.props.authState.defaultLanguage,
   };

   componentDidMount() {
      const itemId = this.props.match.params.id
      Promise.all([
         this.getItem(itemId),
         // this.getComplements()
      ]).then(() => {
         this.setState({
            action: 'edit',
            itemId: itemId,
            pageLoader: false
         })
      })
   }

   getItem = (id) => {
      return storeActions.getItem(id)
         .then(response => {
            console.log(response);
            try {
               // console.log(updatedValues)
               this.setState({ response: response.data, orderData: response.data });
            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
            }
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   handleSaveAndClose = () => {
      this.setState({ saveAndClose: true })
   }

   setDefaultLanguageHandler = (value) => {
      console.log(value);
      const updatedValues = this.setForm(this.state.response, value);
      this.setState({ formValues: updatedValues, componentLanguage: value })
   }

   updateOrderStatus = (status) => {
      console.log('Cancel order', this.state.itemId)
      // console.log(values)
      this.setState({ loaderUpdateOrder: true })
      storeActions.updateOrderStatus(this.state.itemId, status)
         .then(response => {
            console.log(response);
            let newOrderData = { ...this.state.orderData }
            newOrderData.status = response.data.status
            newOrderData.order_status_id = response.data.order_status_id
            this.setState({ orderData: newOrderData, loaderUpdateOrder: false })
            notification.success({
               message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
               description: response.human ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={response.human}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.confirm.updateOk'),
               duration: 0
            })
         }).catch((e) => {
            this.setState({ loaderUpdateOrder: false })
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.human ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.human}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
         })
   }
   cancelOrder = () => {
      console.log('Cancel order', this.state.itemId)
      // console.log(values)
      this.setState({ loaderCancelOrder: true })
      storeActions.cancelOrder(this.state.itemId)
         .then(response => {
            console.log(response);
            this.setState({ loaderCancelOrder: false })
            this.props.history.push('/orders/list');
            notification.success({
               message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
               description: response.human ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={response.human}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.confirm.updateOk'),
               duration: 0
            })
         }).catch((e) => {
            this.setState({ loaderCancelOrder: false })
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.human ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.human}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
         })
   }

   render() {
      if (this.state.pageLoader) return <Loader />
      const orderData = this.state.orderData
      const { Title } = Typography
      const { Option } = Select

      const orderProductsTableLayout = {
         bordered: true,
         size: "small",
         pagination: false,
      }
      const orderProductsColumns = [
         {
            title: 'SKU',
            width: 150,
            dataIndex: 'product_sku',
            key: 'product_sku',
         },
         {
            title: 'SKU Fornitore',
            width: 150,
            dataIndex: 'supplier_sku',
            key: 'supplier_sku',
         },
         {
            title: 'Prodotto',
            key: 'product_name',
            render: (record) => (
               record.product_name
            ),
         },
         {
            title: languageManager.getMessage(this.props.commonState, 'component.order_summary.products.table.unit_price'),
            width: 75,
            key: 'unit_price',
            render: (record) => (
               record.unit_price_human ? record.unit_price_human + ' €' : null
            ),
         },
         {
            title: languageManager.getMessage(this.props.commonState, 'component.order_summary.products.table.quantity'),
            width: 75,
            key: 'quantity',
            render: (record) => (
               record.quantity
            ),
         },
         {
            title: languageManager.getMessage(this.props.commonState, 'component.order_summary.products.table.total_price'),
            width: 75,
            key: 'total_price',
            render: (record) => (
               record.total_price_human ? record.total_price_human + ' €' : null
            ),
         },
         {
            title: languageManager.getMessage(this.props.commonState, 'component.order_summary.products.table.total_tax'),
            width: 75,
            key: 'total_tax',
            render: (record) => (
               record.total_tax_human ? record.total_tax_human + ' €' : null
            ),
         },
      ]

      const order_wrapper = (
         <Space direction='vertical' size='small'>
            <Row gutter={32}>
               <Col span={8}>
                  <Space direction='vertical' size='small'>
                     <Descriptions size='small' column={1} bordered labelStyle={{ width: '200px' }} contentStyle={{ fontWeight: 'bold' }}>
                        <Descriptions.Item label="Numero ordine">{orderData.internal_order_number}</Descriptions.Item>
                        <Descriptions.Item label="Numero ordine fornitore">{orderData.supplier_order_number}</Descriptions.Item>
                        <Descriptions.Item label="Numero ordine cliente">{orderData.customer_order_number}</Descriptions.Item>
                     </Descriptions>
                     <Descriptions size='small' column={1} bordered labelStyle={{ width: '200px' }} contentStyle={{ fontWeight: 'bold' }}>
                        <Descriptions.Item label="Stato ordine">{orderData.purchase_order_status.name}</Descriptions.Item>
                     </Descriptions>
                     <Descriptions size='small' column={1} bordered labelStyle={{ width: '200px' }} contentStyle={{ fontWeight: 'bold' }}>
                        <Descriptions.Item label="Data ordine">{orderData.create_order_date_human}</Descriptions.Item>
                        <Descriptions.Item label="Data consegna prevista">{orderData.deliver_request_date}</Descriptions.Item>
                        <Descriptions.Item label="Data consegna">{orderData.delivered_order_date}</Descriptions.Item>
                     </Descriptions>
                     <Descriptions size='small' column={1} bordered labelStyle={{ width: '200px' }} contentStyle={{ fontWeight: 'bold' }}>
                        <Descriptions.Item label="Numero colli">{orderData.packages_quantity}</Descriptions.Item>
                        <Descriptions.Item label="Numero bancali">{orderData.pallets_quantity}</Descriptions.Item>
                     </Descriptions>
                     <Descriptions size='small' column={1} bordered labelStyle={{ width: '200px' }} contentStyle={{ fontWeight: 'bold' }}>
                        <Descriptions.Item label="Note">{orderData.order_note}</Descriptions.Item>
                        {
                           !_.isNil(orderData.customer_note) ? (
                              <Descriptions.Item label="Note cliente">{orderData.customer_note}</Descriptions.Item>
                           ) : null
                        }
                     </Descriptions>
                  </Space>
               </Col>
               <Col span={16}>
                  <Space direction='vertical'>
                     {
                        this.state.isCustomer ? null : (
                           <Descriptions size='small' column={1} bordered labelStyle={{ width: '150px' }} contentStyle={{ fontWeight: 'bold' }} title='Dati fornitore'>
                              <Descriptions.Item label="Ragione sociale">{orderData.supplier.company}</Descriptions.Item>
                              <Descriptions.Item label="P.IVA">{orderData.supplier.vat}</Descriptions.Item>
                              <Descriptions.Item label="Codice Fiscale">{orderData.supplier.fiscal_code}</Descriptions.Item>
                              <Descriptions.Item label="Telefono">{orderData.supplier.phone}</Descriptions.Item>
                              <Descriptions.Item label="Email">{orderData.supplier.email}</Descriptions.Item>
                              <Descriptions.Item label="PEC">{orderData.supplier.pec}</Descriptions.Item>
                              <Descriptions.Item label="SDI">{orderData.supplier.sdi}</Descriptions.Item>
                           </Descriptions>
                        )
                     }
                     <Descriptions size='small' column={1} bordered labelStyle={{ width: '150px' }} contentStyle={{ fontWeight: 'bold' }} title='Dati cliente'>
                        <Descriptions.Item label="Ragione sociale">{_.isNil(orderData.customer) ? null : orderData.customer.company}</Descriptions.Item>
                        {
                           _.isNil(orderData.customer) ? null : ([
                              <Descriptions.Item label="P.IVA">{orderData.customer.vat}</Descriptions.Item>,
                              <Descriptions.Item label="Codice Fiscale">{orderData.customer.fiscal_code}</Descriptions.Item>,
                              <Descriptions.Item label="Telefono">{orderData.customer.phone}</Descriptions.Item>,
                              <Descriptions.Item label="Email">{orderData.customer.email}</Descriptions.Item>,
                              <Descriptions.Item label="PEC">{orderData.customer.pec}</Descriptions.Item>,
                              <Descriptions.Item label="SDI">{orderData.customer.sdi}</Descriptions.Item>,
                           ])
                        }
                     </Descriptions>
                  </Space>
               </Col>
            </Row>
            <Row gutter={32}>
               <Col span={24}>
                  <Table {...orderProductsTableLayout}
                     columns={orderProductsColumns}
                     dataSource={orderData.order_lines}
                  />
               </Col>
            </Row>
            <Row gutter={32}>
               <Col span={8} offset={16}>
                  <Descriptions size='small' column={1} bordered labelStyle={{ width: '200px' }} contentStyle={{ fontWeight: 'bold' }}>
                     <Descriptions.Item label="Valore prodotti">{orderData.total_value_human + ' €'}</Descriptions.Item>
                     <Descriptions.Item label="Totale supplementi">{orderData.total_extra_charges_human + ' €'}</Descriptions.Item>
                  </Descriptions>
               </Col>
            </Row>
         </Space>
      )

      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="row">
                  <div className="col-sm-6">
                     <Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.purchase_orders.details')}</Title>
                  </div>
                  <div className="col-sm-6">
                     {/* <div className="float-right d-none d-md-block">
                        <Space>
                           <Select
                              showSearch
                              className="width-70px-I"
                              placeholder="Seleziona la lingua"
                              optionFilterProp="children"
                              onChange={this.setDefaultLanguageHandler}
                           >
                              {
                                 this.props.authState.userData.languages.map((lang) => {
                                    return <Option key={lang.value} value={lang.value}>{lang.label}</Option>
                                 })
                              }
                           </Select>
                        </Space>
                     </div> */}
                  </div>
               </div>
            </div>
            <div className="card-body">
               {order_wrapper}
            </div>
            <div className="card-footer">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     <Space size='small'>
                        <Link to={{ pathname: "/purchase_orders/list" }} >
                           <Button icon={<DoubleLeftOutlined />}>
                              {languageManager.getMessage(this.props.commonState, 'common.back')}
                           </Button>
                        </Link>
                     </Space>
                  </div>
                  <div className="col-sm-6">
                     {/* <div className="float-right d-none d-md-block">
                        <Space size='small'>
                           <Link to={{ pathname: "/orders/edit/" + this.state.itemId }} >
                              <Button type='primary' icon={<EditOutlined />}
                                 disabled={this.state.loaderCancelOrder} >
                                 {languageManager.getMessage(this.props.commonState, 'common.edit')}
                              </Button>
                           </Link>
                           <Button type='danger' icon={<CloseOutlined />}
                              loading={this.state.loaderCancelOrder}
                              title="Annulla ordine"
                              onClick={() =>
                                 Modal.confirm({
                                    title: languageManager.getMessage(this.props.commonState, 'component.orders.cancel_order'),
                                    icon: <ExclamationCircleOutlined />,
                                    content: languageManager.getMessage(this.props.commonState, 'common.askOperationConfirm'),
                                    okText: languageManager.getMessage(this.props.commonState, 'common.confirm'),
                                    onOk: this.cancelOrder
                                 })
                              }
                           />
                        </Space>
                     </div> */}
                  </div>
               </div>
            </div>
         </div>
      );

      const helpButton = {
         title: "Aiuto"
      }
      const buttons = [];
      buttons.push(helpButton);

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>

      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   };
};

export default connect(mapStateToProps)(PurchaseOrderDetails);