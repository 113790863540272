export enum OrdersStatsRangeEnum {
   DAILY,
   WEEKLY,
   MONTHLY,
   QUARTERLY,
   YEARLY,
}

export enum OrderStatusEnum {
   NULL,
   WAITING_ACCEPTANCE,
   ACCEPTED_TOCHECK,
   UPDATED_TOCHECK,
   PROCESSING,
   REFUSED_TOCHECK,
   CLOSED,
   REFUSED,
   UNKNOWN
}

export enum ClosingOrderStatusEnum {
   NULL,
   TOSEND,
   TOCHECK,
   SENT,
}

export enum ManualUpdateStatusEnum {
   IN_PROGRESS,
   IMPORTED,
   ORDERS_UPDATED,
   SENT_TO_AMAZON,
}

export enum OrderStatusActionEnum {
   ACCEPT,
   ACCEPT_PARTIAL,
   REFUSE,
   PROCESS,
}

export enum ProductsImportFileCSVType {
   NULL,
   QUANTITIES,
   PRICES,
   IMAGES,
}
export enum ProductsCatalogTypeEnum {
   NULL,
   NIS,
   RIA,
   BOTH,
}
export enum ProductsAvailabilityEnum {
   OBSOLETE,
   IN_STOCK,
   TEMP_NOT_AVAILABLE,
}

export enum OrdersImportFileCSVType {
   NULL,
   TRACKING,
}

export enum VendorCodeStatusPriceEnum {
   MISSING_VENDOR_PRICE,
   DIFFERENT_VENDOR_PRICE,
   VENDOR_PRICE_OK,
}
export enum VendorCodeStatusCatalogEnum {
   API_NOT_SEARCHED,
   API_NOT_FOUND,
   API_OK,
}

export enum ProgressiveQuarterEnum {
   NULL,
   Q1,
   Q2,
   Q3,
   Q4,
}

export enum ConfirmationActionEnum {
   NULL,
   ADD,
   REMOVE,
   REPLACE,
   NO_UPDATES,
}

export enum PurchaseOrderTypeEnum {
   NULL,
   SEASONAL,
   RIA,
   DIRECT,
   STOCK,
   UNKNOWN,
}
export enum PurchaseOrderGroupEnum {
   NULL,
   FTW,
   PES,
   LUGG,
}