import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import Moment from 'moment'

import { Drawer, Table, Button, Space, notification, Descriptions } from 'antd'
import { } from '@ant-design/icons';

import languageManager from '../../../../services/languageManager';
import * as storeActions from '../store/actions/products';

class DrawerMovements extends Component {
   state = {
      ...this.props,
      componentLoader: true,
      productData: {},
      productMovements: [],
      productMovementsQuantity: null,
      componentLanguage: this.props.authState.defaultLanguage
   }
   formRef = React.createRef();

   componentDidMount() {
      console.log('Component did mount')
      if (this.props.itemId) {
         Promise.all([
            this.getItem(this.props.itemId),
            this.getProductMovements(this.props.itemId)
         ]).then(() => {
            this.setState({
               componentLoader: false
            });
         });
      }
   }

   shouldComponentUpdate = (nextProps, nextState) => {
      // console.log('Component did update', nextProps, this.state)
      if (_.isEqual(nextProps.visible, this.props.visible)
         && _.isEqual(nextState, this.state)) {
         return false
      }
      return true
   }

   componentDidUpdate = (prevProps, prevState) => {
      if (!_.isEqual(prevProps.visible, this.props.visible)) {
         console.log('Component did update', this.props)
         if (this.props.itemId) {
            this.setState({ componentLoader: true });
            Promise.all([
               this.getItem(this.props.itemId),
               this.getProductMovements(this.props.itemId)
            ]).then(() => {
               this.setState({ componentLoader: false });
            });
         }
      }
   }

   getItem = (id) => {
      return storeActions.getItem(id)
         .then(response => {
            console.log(response);
            const productData = {
               // parent_name: response.parent ? response.parent.name[this.state.componentLanguage] : 'Prodotto padre',
               sku: response.data.sku,
               supplier_sku: response.data.supplier_sku,
               name: response.data.name,
               quantity: response.data.quantity,
               quantity_updated_at: response.data.quantity_updated,
               quantity_updated_at_human: _.isNil(response.data.quantity_updated_at_human) ? 'MAI' : response.data.quantity_updated_at_human,
            }
            // console.log(updatedValues)
            this.setState({ productData: productData });
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }
   getProductMovements = (productId) => {
      return storeActions.getMovements(productId)
         .then(response => {
            console.log(response);
            this.setState({ productMovements: response.data.movements, productMovementsQuantity: response.data.quantity });
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   onClose = () => {
      this.state.onClose()
   }

   render() {
      console.log('Render movement drawer', this.state)

      const table_columns = [
         {
            title: 'Numero ordine interno',
            key: 'internal_order_number',
            width: 200,
            render: (record) => (
               record.charge ? (
                  <Link to={'/purchase_orders/details/' + record.purchase_order_id}>{record.internal_order_number}</Link>
               ) : (
                  <Link to={'/orders/details/' + record.order_id}>{record.internal_order_number}</Link>
               )
            ),
         },
         {
            title: 'Numero ordine fornitore',
            width: 150,
            ellipsis: true,
            key: 'supplier_order_number',
            render: (record) => (
               record.supplier_order_number
            ),
         },
         {
            title: 'Numero ordine cliente',
            width: 150,
            ellipsis: true,
            key: 'customer_order_number',
            render: (record) => (
               record.customer_order_number
            ),
         },
         {
            title: 'Data',
            width: 140,
            key: 'create_order_date',
            render: (record) => (
               record.movement_date_human
            ),
            sorter: (a, b) => {
               a = a.movement_date || ''
               b = b.movement_date || ''
               return a.localeCompare(b)
            },
         },
         {
            title: 'Entrata',
            width: 75,
            className: 'movement-charge-column',
            key: 'charge',
            render: (text, record) => (
               record.charge
            ),
         },
         {
            title: 'Uscita',
            width: 75,
            className: 'movement-discharge-column',
            key: 'discharge',
            render: (text, record) => (
               record.discharge
            ),
         },
      ];
      const tableLayout = {
         bordered: true,
         className: "table-striped-rows",
         size: "small",
         loading: this.state.tableLoader,
         pagination: false
         // pagination: {
         //    pageSize: this.state.page_size,
         //    onChange: this.listPaginationChangeHandler,
         //    total: this.state.total_result
         // },
      }

      return (
         <Drawer
            title="Visualizza movimenti di magazzino"
            width={850}
            onClose={this.onClose}
            visible={this.props.visible}
            drawerStyle={{ paddingTop: 70 }}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
               <div className='float-right'>
                  <Space>
                     <Button onClick={this.onClose} disabled={this.state.formLoader}>Chiudi</Button>
                  </Space>
               </div>
            }
         >
            <Space direction="vertical" size="large">
               <Descriptions bordered column={1} size={'small'}>
                  <Descriptions.Item label="Prodotto">{this.state.productData.name}</Descriptions.Item>
                  {/* <Descriptions.Item label="Prodotto padre">{this.state.productData.parent_name}</Descriptions.Item> */}
                  <Descriptions.Item label="SKU">{this.state.productData.sku}</Descriptions.Item>
                  <Descriptions.Item label="SKU fornitore">{this.state.productData.supplier_sku}</Descriptions.Item>
                  <Descriptions.Item label="Quantità movimentata">{this.state.productMovementsQuantity}</Descriptions.Item>
                  <Descriptions.Item label="Quantità in magazzino">{this.state.productData.quantity + ' (ultimo aggiornamento: ' + this.state.productData.quantity_updated_at_human + ')'}</Descriptions.Item>
               </Descriptions>
               <Table {...tableLayout}
                  columns={table_columns}
                  dataSource={this.state.productMovements}
                  rowKey={(record) => (record.id)}
                  loading={this.state.componentLoader}
               />
            </Space>
         </Drawer>
      )
   }
}

function mapStateToProps(state) {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
}

export default connect(mapStateToProps)(DrawerMovements)