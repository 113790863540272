import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import Moment from 'moment'

import { Divider, Modal, Table, Form, Radio, Input, InputNumber, Select, DatePicker, Button, Space, notification, Tabs, Typography, Row, Col, Descriptions, List } from 'antd'
import { CheckOutlined, CloseOutlined, DoubleLeftOutlined, PlusOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined, UploadOutlined } from '@ant-design/icons'

import languageManager from '../../../services/languageManager'
import * as storeActions from './store/actions/orders'
import * as suppliersActions from '../../master_data/suppliers/store/actions/suppliers'

import Loader from '../../common/PageElements/Loader'
import { startLoading, endLoading, download } from '../../../shared/utility'

import ModalAddOrderProduct from './components/ModalAddProduct'

class PurchaseOrderEdit extends Component {

   state = {
      isCustomer: !_.isNil(this.props.authState.userData.account.parent_account_id),
      supplierId: !_.isNil(this.props.authState.userData.account.supplier) ? this.props.authState.userData.account.supplier.id : null,
      itemId: null,
      saveAndClose: false,
      pageLoader: true,
      formLoader: false,
      loadersLines: [],
      loaderInternalCode: false,
      errorInternalCode: null,
      loaderSupplierCode: false,
      errorSupplierCode: null,
      formValues: {},

      loaderOrderSave: false,
      componentLanguage: this.props.authState.defaultLanguage,

      supplierList: [],
      shippingMethodList: [],
      paymentMethodList: [],
      bankList: [],
      taxRateList: [],
      productCascaderList: [],
      productCascaderListForSupplier: [],
      supplierExtraCharges: [],

      orderLines: [],
      orderTotalQuantity: 0.00,
      orderTotalWeight: 0.00,
      orderTotalVolume: 0.00,
      orderTotalProducts: 0.00,
      orderTotalTax: 0.00,
      orderTotal: 0.00,
      shippingTotal: 0.00,
      extrachargesTotal: 0.00,

      showModalChooseShippingAddress: false,
      showModalChooseInvoicingAddress: false,
      showModalAddProduct: false,
      showModalAddOrderLine: false,
      modalEditLineItem: null,

   };
   formRef = React.createRef();

   componentDidMount() {
      if (this.props.match.params.id) {
         const itemId = this.props.match.params.id
         Promise.all([
            this.getItem(itemId),
            this.getComplements()
         ]).then(() => {
            this.setState({
               itemId: itemId,
               pageLoader: false
            })
         })
      }
      else {
         const formValues = {
            language: this.state.componentLanguage,
            internal_order_number: this.props.authState.userData.account.id.toString().padStart(4, '0') + '_' + Moment().format('YYMMDDHHmmss'),
            create_order_date: Moment(),
            order_status_id: 1,
            supplier_id: this.state.supplierId,
         }
         Promise.all([
            this.getComplements()
         ]).then(() => {
            this.setState({
               formValues: formValues,
               action: 'insert',
               pageLoader: false
            });
         });
      }
   }
   getItem = (id) => {
      return storeActions.getItem(id)
         .then(response => {
            console.log(response);
            try {
               const updatedValues = this.setForm(response.data);
               // console.log(updatedValues)
               this.setState({ response: response.data, orderLines: response.data.order_lines, formValues: updatedValues })
               this.getTotalsFromLines(response.data.order_lines);
            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
               if (this.props.commonState.debug) console.log(e);
            }
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }
   getComplements = () => {
      return storeActions.getComplements()
         .then(response => {
            console.log(response);
            try {
               this.setState({
                  response: response,
                  customerList: response.customerList,
                  supplierList: response.supplierList,
                  shippingMethodList: response.shippingMethodList,
                  productCascaderList: response.productCascaderList,
                  productCascaderListForSupplier: response.productCascaderList,
                  extraChargesList: _.filter(response.extraChargesList, { apply_on_charging: 0 }),
               });

            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
               if (this.props.commonState.debug) console.log(e);
            }

         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }
   handleSaveAndClose = () => {
      this.setState({ saveAndClose: true });
   }

   setForm = (responseValues, lang = this.state.componentLanguage) => {
      let values = { ...responseValues }
      if (!_.isNil(values.create_order_date)) {
         const newDate = Moment(values.create_order_date)
         values.create_order_date = newDate
      }
      if (!_.isNil(values.shipping_request_date)) {
         const newDate = Moment(values.shipping_request_date)
         values.shipping_request_date = newDate
      }
      if (!_.isNil(values.shipped_order_date)) {
         const newDate = Moment(values.shipped_order_date)
         values.shipped_order_date = newDate
      }

      values.order_status_name = responseValues.order_status.name.it

      values.supplier_id = _.isNil(responseValues.supplier) ? null : responseValues.supplier.id
      values.customer_id = _.isNil(responseValues.customer) ? null : responseValues.customer.id
      const actualSupplier = responseValues.supplier
      const actualCustomer = responseValues.customer

      _.forEach(responseValues.extra_charges, (extracharge, i) => {
         values['extracharge_quantity_' + extracharge.id] = extracharge.pivot.quantity
         values['extracharge_' + extracharge.id] = _.isNil(extracharge.pivot.unit_cost_no_vat) ? null : extracharge.pivot.unit_cost_no_vat
      });

      this.setState({
         actualSupplier: actualSupplier,
         actualCustomer: actualCustomer,
         extrachargesTotal: values.total_extra_charges
      })

      return values;
   }

   getSupplier = (supplierId) => {
      return suppliersActions.getItem(supplierId)
         .then(response => {
            console.log(response);
            try {
               this.setState({ actualSupplier: response.data })
            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
               if (this.props.commonState.debug) console.log(e);
            }
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }
   getSupplierExtraCharges = (supplierId) => {
      return suppliersActions.getExtraCharges(supplierId)
         .then(response => {
            console.log(response);
            try {
               // const updatedValues = this.setForm(response.data);
               // console.log(updatedValues)
               // this.setState({ response: response.data, formValues: updatedValues });

               this.setState({ supplierExtraCharges: response.data });
            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
            }
         }).catch((e) => {
            this.setState({ getError: true });
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   handleSupplierChange = (value) => {
      console.log('handleSupplierChange', value)
      this.setState({ loaderSupplier: true })
      if (_.isUndefined(value)) {
         _.forEach(this.state.extraChargesList, (value, key) => {
            const fieldName = 'extracharge_' + value.id;
            const fieldValue = null;
            this.formRef.current.setFieldValue(fieldName, fieldValue);
         })
         this.setState({
            productCascaderListForSupplier: this.state.productCascaderList,
            actualSupplier: null,
            actualShippingAddress: null,
            actualBillingAddress: null,
            loaderSupplier: false
         })
      } else {
         Promise.all([
            this.getSupplier(value),
            this.getSupplierExtraCharges(value)
         ]).then(() => {
            // console.log(this.formRef)
            // console.log('initial value', this.formRef.current.getFieldValue('extracharge_13'))
            // console.log('initial value', this.formRef.current.getFieldValue('create_order_date'))
            // console.log('Extra Charges', this.state.extraChargesList)
            // console.log('Supplier Extra Charges', this.state.supplierExtraCharges)
            _.forEach(this.state.extraChargesList, (value, key) => {
               const fieldName = 'extracharge_' + value.id;
               const supplierExtraCharge = _.isNil(this.state.supplierExtraCharges.find(obj => { return obj.extra_charge_id == value.id })) ? null : this.state.supplierExtraCharges.find(obj => { return obj.extra_charge_id == value.id })
               const fieldValue = _.isNil(supplierExtraCharge) || _.isNil(supplierExtraCharge.cost_total) ? null : supplierExtraCharge.cost_total
               // console.log('Extra charge field', fieldName, fieldValue);
               this.formRef.current.setFieldValue(fieldName, fieldValue);
            })
            let productList = _.filter(this.state.productCascaderList, { supplier_id: value })
            this.setState({
               productCascaderListForSupplier: productList,
               loaderSupplier: false
            })
         })
      }
   }

   getTotalsFromLines = (actualLines) => {
      console.log('Order Lines:', actualLines)
      let total_products = 0
      let total_tax = 0
      let total = 0
      let quantity = 0
      _.forEach(actualLines, (line, i) => {
         if (line.total_price) total_products += parseFloat(line.total_price)
         if (line.total_tax) total_tax += parseFloat(line.total_tax)
         if (line.total_price && line.total_tax) total += (parseFloat(line.total_price) + parseFloat(line.total_tax))
         quantity += parseFloat(line.quantity)
      });
      this.setState({
         orderTotalProducts: total_products,
         orderTotalTax: total_tax,
         orderTotal: total,
         orderTotalQuantity: quantity,
         orderLines: actualLines,
         loaderOrderLines: false
      });
   }
   showModalAddProduct = (line, index) => {
      if (!_.isNil(line)) {
         line.line_index = index
         this.setState({
            modalEditLineItem: line,
            showModalAddProduct: true,
         })
      } else {
         this.setState({ modalEditLineItem: null, showModalAddProduct: true })
      }
   }
   hideModalAddProduct = () => {
      this.setState({ modalEditLineIndex: null, modalEditLineItem: null, showModalAddProduct: false })
   }
   handleAddProduct = (line) => {
      console.log('handleAddProduct', line)
      this.setState({ loaderOrderLines: true })
      let actualLines = []
      if (!_.isEmpty(this.state.orderLines)) {
         actualLines = this.state.orderLines.map(a => { return { ...a } })
      }
      if (_.isNil(line.line_index)) {
         line.key = Moment().unix()
         line.expiration_date = !_.isNil(line.batch_expiration) ? Moment(line.batch_expiration, 'DD/MM/YYYY').format('YYYY-MM-DD') : null
         actualLines.push(line)
      } else {
         const lineIndex = actualLines.findIndex((obj => obj.line_index == line.line_index))
         line.expiration_date = !_.isNil(line.batch_expiration) ? Moment(line.batch_expiration, 'DD/MM/YYYY').format('YYYY-MM-DD') : null
         actualLines[lineIndex] = line
      }
      this.getTotalsFromLines(actualLines)
   }
   handleRemoveProduct = lineIndex => {
      console.log('handleRemoveProduct', lineIndex)
      const actualLines = this.state.orderLines.filter((obj, index) => index !== lineIndex)
      this.getTotalsFromLines(actualLines)
   }

   calcExtracharges = () => {
      let total = 0
      let extrachargeFields = []
      _.forEach(this.state.extraChargesList, (value, key) => {
         const quantity = this.formRef.current.getFieldValue('extracharge_quantity_' + value.id);
         const cost = this.formRef.current.getFieldValue('extracharge_' + value.id);
         const subtotal = !_.isNil(quantity) && !_.isNil(cost) ? (quantity * cost).toFixed(2) : 0;
         total += parseFloat(subtotal)
         const extraCharge = {
            quantity: quantity,
            cost: cost,
            subtotal: subtotal
         }
         extrachargeFields.push(extraCharge)
      })
      console.log('Extra Charge Fields:', extrachargeFields, total)
      this.setState({ extrachargesTotal: total })
   }

   onFinish = values => {
      if (!_.isNil(this.state.itemId)) {
         values.id = this.state.itemId
      }
      values.order_status_id = this.state.formValues.order_status_id
      values.order_lines = this.state.orderLines
      values.total_quantity = this.state.orderTotalQuantity
      values.total_products = this.state.orderTotalProducts
      values.total_tax = this.state.orderTotalTax
      values.total = this.state.orderTotal
      values.total_extracharges = this.state.extrachargesTotal

      let extra_charges = [];
      _.forEach(this.state.extraChargesList, (value, key) => {
         const quantity = this.formRef.current.getFieldValue('extracharge_quantity_' + value.id);
         const cost = this.formRef.current.getFieldValue('extracharge_' + value.id);
         if (!_.isNil(quantity) && !_.isNil(cost)) {
            const subtotal = (quantity * cost).toFixed(2);
            const extraCharge = {
               extra_charge_id: value.id,
               quantity: quantity,
               unit_cost_no_vat: cost,
               total_cost_no_vat: subtotal
            }
            extra_charges.push(extraCharge)
         }
      })
      values.extra_charges = extra_charges;
      // console.log(values);
      this.setState({ formLoader: true });
      storeActions.createOrUpdate(values)
         .then(response => {
            console.log(response);
            try {
               if (this.state.saveAndClose) {
                  this.props.history.goBack();
               }
               const updatedValues = this.setForm(response.data);
               // this.setState({ response: response.data, itemId: response.data.id, action: 'edit', formValues: updatedValues, formLoader: false });
               this.setState({ itemId: response.data.id, formValues: updatedValues, orderLines: response.data.order_lines, formLoader: false })

               notification.success({
                  message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
                  description: languageManager.getMessage(this.props.commonState, this.state.itemId ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
               })
            } catch (e) {

            }
         }).catch((e) => {
            console.log('ERRORE', e);
            this.setState({ formLoader: false });
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
         })
   }
   onFinishFailed = errorInfo => {
      notification.error({
         message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
         description: languageManager.getMessage(this.props.commonState, 'notification.error.formInvalid'),
      })
   };

   updateOrderStatus = (status) => {
      console.log('Update order status', this.state.itemId, status)
      // console.log(values)
      this.setState({ loaderUpdateOrder: true })
      storeActions.updateOrderStatus(this.state.itemId, status)
         .then(response => {
            console.log(response);
            Promise.all([
               this.getItem(this.state.itemId)
            ]).then(() => {
               this.setState({ loaderUpdateOrder: false })
               notification.success({
                  message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
                  description: response.human ? (
                     <List
                        size="small"
                        split={false}
                        dataSource={response.human}
                        renderItem={item => <List.Item>{item}</List.Item>}
                     />
                  ) : languageManager.getMessage(this.props.commonState, 'notification.confirm.updateOk'),
                  duration: 0
               })
            })
            // let newOrderData = { ...this.state.orderData }
            // newOrderData.order_status = response.data.order_status
         }).catch((e) => {
            this.setState({ loaderUpdateOrder: false })
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.human ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.human}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
         })
   }
   cancelOrder = () => {
      console.log('Cancel order', this.state.itemId)
      // console.log(values)
      this.setState({ loaderCancelOrder: true })
      storeActions.cancelOrder(this.state.itemId)
         .then(response => {
            console.log(response);
            this.setState({ loaderCancelOrder: false })
            this.props.history.push('/orders/list');
            notification.success({
               message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
               description: response.human ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={response.human}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.confirm.updateOk'),
               duration: 0
            })
         }).catch((e) => {
            this.setState({ loaderCancelOrder: false })
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.human ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.human}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
         })
   }
   sendToLogistics = () => {
      console.log('Send to Logistics', this.state.itemId)
      // console.log(values)
      this.setState({ loaderSendLogistics: true })
      storeActions.sendToLogistics(this.state.itemId)
         .then(response => {
            console.log(response);
            Promise.all([
               this.getItem(this.state.itemId)
            ]).then(() => {
               this.setState({ loaderSendLogistics: false })
               notification.success({
                  message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
                  description: response.human ? (
                     <List
                        size="small"
                        split={false}
                        dataSource={response.human}
                        renderItem={item => <List.Item>{item}</List.Item>}
                     />
                  ) : languageManager.getMessage(this.props.commonState, 'notification.confirm.updateOk'),
                  duration: 0
               })
            })
         }).catch((e) => {
            this.setState({ loaderSendLogistics: false })
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.human ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.human}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
         })
   }
   sendToQuadrivium = () => {
      console.log('Send to Quadrivium', this.state.itemId)
      // console.log(values)
      this.setState({ loaderSendQV: true })
      storeActions.sendToQuadrivium(this.state.itemId)
         .then(response => {
            console.log(response);
            Promise.all([
               this.getItem(this.state.itemId)
            ]).then(() => {
               this.setState({ loaderSendQV: false })
               notification.success({
                  message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
                  description: response.human ? (
                     <List
                        size="small"
                        split={false}
                        dataSource={response.human}
                        renderItem={item => <List.Item>{item}</List.Item>}
                     />
                  ) : languageManager.getMessage(this.props.commonState, 'notification.confirm.updateOk'),
                  duration: 0
               })
            })
         }).catch((e) => {
            this.setState({ loaderSendQV: false })
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.human ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.human}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
         })
   }


   render() {
      if (this.state.pageLoader) return <Loader />
      // console.log(this.state.orderLines)
      const { Title, Text } = Typography

      const mainLayout = {
         labelCol: { span: 6 },
         wrapperCol: { span: 14 },
         layout: "horizontal",
      };
      const sideLayout = {
         labelCol: { span: 10 },
         wrapperCol: { span: 14 },
         layout: "horizontal",
      };
      const { TextArea } = Input
      const { Option } = Select
      const { TabPane } = Tabs

      const orderProductsTableLayout = {
         bordered: true,
         size: "small",
         pagination: false,
         loading: this.state.loaderOrderLines,
      }
      const orderProductsColumns = [
         {
            title: 'SKU',
            width: 150,
            dataIndex: 'product_sku',
            key: 'product_sku',
         },
         {
            title: 'Codice fornitore',
            width: 150,
            dataIndex: 'supplier_sku',
            key: 'supplier_sku',
         },
         {
            title: 'Prodotto',
            key: 'product_name',
            render: (record) => (
               record.product_name
            ),
         },
         // {
         //    title: 'Lotto',
         //    width: 150,
         //    dataIndex: 'batch',
         //    key: 'batch',
         // },
         // {
         //    title: 'Scadenza',
         //    width: 100,
         //    key: 'expiration_date',
         //    render: (record) => (
         //       record.expiration_date ? Moment(record.expiration_date).format('L') : null
         //    ),
         // },
         {
            title: languageManager.getMessage(this.props.commonState, 'component.order_summary.products.table.unit_price'),
            width: 75,
            key: 'unit_price',
            render: (record) => (
               record.unit_price ? parseFloat(record.unit_price).toFixed(2) + '€' : null
            ),
         },
         {
            title: languageManager.getMessage(this.props.commonState, 'component.order_summary.products.table.quantity'),
            width: 75,
            key: 'quantity',
            render: (record) => (
               record.quantity ? parseFloat(record.quantity).toFixed(2) : null
            ),
         },
         {
            title: languageManager.getMessage(this.props.commonState, 'component.order_summary.products.table.total_price'),
            width: 75,
            key: 'total_price',
            render: (record) => (
               record.total_price ? parseFloat(record.total_price).toFixed(2) + '€' : null
            ),
         },
         {
            title: languageManager.getMessage(this.props.commonState, 'component.order_summary.products.table.total_tax'),
            width: 75,
            key: 'total_tax',
            render: (record) => (
               record.total_tax ? parseFloat(record.total_tax).toFixed(2) + '€' : null
            ),
         },
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            width: 150,
            render: (text, record, index) => (
               <Space size='small'>
                  <Button type="primary" size='small'
                     icon={<EditOutlined />}
                     onClick={() => this.showModalAddProduct(record, index)}
                     disabled={this.state.formValues.order_status_id > 3}
                  />
                  <Button type="danger" size='small'
                     loading={this.state.loadersLines[record.line_index]}
                     icon={<DeleteOutlined />}
                     onClick={() =>
                        Modal.confirm({
                           title: languageManager.getMessage(this.props.commonState, 'component.orders.delete_order_line'),
                           icon: <ExclamationCircleOutlined />,
                           content: languageManager.getMessage(this.props.commonState, 'common.askOperationConfirm'),
                           okText: languageManager.getMessage(this.props.commonState, 'common.confirm'),
                           onOk: () => this.handleRemoveProduct(record.line_index)
                        })
                     }
                     disabled={this.state.formValues.order_status_id > 3}
                  />
               </Space>
            ),
         },
      ]

      const PurchaseOrderForm = (
         <Form
            ref={this.formRef}
            {...mainLayout}
            id="OrderCreateForm"
            onFinish={this.onFinish}
            initialValues={this.state.formValues}
         >
            <Row gutter={32}>
               <Col span={8}>
                  <Form.Item
                     {...sideLayout}
                     label="Numero ordine"
                     name="internal_order_number"
                     rules={[{ required: true, message: 'Inserisci il numero dell\'ordine' }]}
                  >
                     <Input disabled={this.state.isCustomer || this.state.formValues.order_status_id > 2} />
                  </Form.Item>
                  <Form.Item
                     {...sideLayout}
                     label="Numero ordine fornitore"
                     name="supplier_order_number"
                  >
                     <Input disabled={this.state.formValues.order_status_id > 2} />
                  </Form.Item>
                  <Form.Item
                     {...sideLayout}
                     label="Numero ordine cliente"
                     name="customer_order_number"
                  >
                     <Input disabled={this.state.formValues.order_status_id > 2} />
                  </Form.Item>
                  <Divider />
                  <Form.Item
                     {...sideLayout}
                     label="Data ordine"
                     name="create_order_date"
                     rules={[{ required: true, message: 'Inserisci la data dell\'ordine' }]}
                  >
                     <DatePicker showTime
                        placeholder="Data ordine"
                        format="DD/MM/YYYY HH:mm:ss"
                        allowClear={true}
                        disabled={this.state.isCustomer || this.state.formValues.order_status_id > 2}
                     />
                  </Form.Item>
                  <Form.Item
                     {...sideLayout}
                     label="Data di spedizione richiesta"
                     name="shipping_request_date"
                  >
                     <DatePicker
                        placeholder="Data di spedizione richiesta"
                        format="DD/MM/YYYY"
                        allowClear={true}
                        disabled={this.state.formValues.order_status_id > 2}
                     />
                  </Form.Item>
                  <Form.Item
                     {...sideLayout}
                     label="Data di spedizione"
                     name="shipped_order_date"
                  >
                     <DatePicker showTime
                        placeholder="Ordine non spedito"
                        format="DD/MM/YYYY HH:mm:ss"
                        disabled
                     />
                  </Form.Item>
                  {
                     this.state.isCustomer ? null : ([
                        <Divider />,
                        <Form.Item
                           {...sideLayout}
                           label="Numero colli"
                           name="packages_quantity"
                           key="packages_quantity"
                           rules={[]}
                        >
                           <InputNumber step={1} min={0} />
                        </Form.Item>,
                        <Form.Item
                           {...sideLayout}
                           label="Numero bancali"
                           name="pallets_quantity"
                           key="pallets_quantity"
                           rules={[]}
                        >
                           <InputNumber step={1} min={0} />
                        </Form.Item>
                     ])
                  }
                  <Divider />
                  <Form.Item
                     {...sideLayout}
                     label="Note"
                     name="order_note"
                  >
                     <TextArea disabled={this.state.formValues.order_status_id > 2} />
                  </Form.Item>
               </Col>
               <Col span={16}>
                  {
                     this.state.itemId && !this.state.isCustomer ? (
                        <Form.Item
                           label="Stato ordine"
                        >
                           <Row gutter={8}>
                              <Col span={12}>
                                 <Input disabled value={this.state.formValues.order_status.name} />
                              </Col>
                              <Col span={12}>
                                 <Space size={'small'}>
                                    {
                                       this.state.formValues.order_status_id === 1 ? (
                                          <Button type='success' icon={<CheckOutlined />}
                                             loading={this.state.loaderUpdateOrder}
                                             onClick={() =>
                                                Modal.confirm({
                                                   title: languageManager.getMessage(this.props.commonState, 'component.orders.update_order'),
                                                   icon: <ExclamationCircleOutlined />,
                                                   content: languageManager.getMessage(this.props.commonState, 'common.askOperationConfirm'),
                                                   okText: languageManager.getMessage(this.props.commonState, 'common.confirm'),
                                                   onOk: () => this.updateOrderStatus('PROCESSING')
                                                })
                                             }
                                          >Conferma ordine</Button>
                                       ) : this.state.formValues.order_status_id === 2 ? (
                                          <Button type='success' icon={<CheckOutlined />}
                                             loading={this.state.loaderUpdateOrder}
                                             onClick={() =>
                                                Modal.confirm({
                                                   title: languageManager.getMessage(this.props.commonState, 'component.orders.update_order'),
                                                   icon: <ExclamationCircleOutlined />,
                                                   content: languageManager.getMessage(this.props.commonState, 'common.askOperationConfirm'),
                                                   okText: languageManager.getMessage(this.props.commonState, 'common.confirm'),
                                                   onOk: () => this.updateOrderStatus('WAITING_SHIPMENT')
                                                })
                                             }
                                          >Metti in attesa di spedizione</Button>
                                       ) : this.state.formValues.order_status_id === 3 ? (
                                          <Button type='success' icon={<CheckOutlined />}
                                             loading={this.state.loaderUpdateOrder}
                                             onClick={() =>
                                                Modal.confirm({
                                                   title: languageManager.getMessage(this.props.commonState, 'component.orders.update_order'),
                                                   icon: <ExclamationCircleOutlined />,
                                                   content: languageManager.getMessage(this.props.commonState, 'common.askOperationConfirm'),
                                                   okText: languageManager.getMessage(this.props.commonState, 'common.confirm'),
                                                   onOk: () => this.updateOrderStatus('SHIPPED')
                                                })
                                             }
                                          >Conferma spedizione</Button>
                                       ) : null
                                    }
                                    {
                                       this.state.formValues.order_status_id < 4 ? (
                                          <Button type='danger' icon={<CloseOutlined />}
                                             loading={this.state.loaderCancelOrder}
                                             onClick={() =>
                                                Modal.confirm({
                                                   title: languageManager.getMessage(this.props.commonState, 'component.orders.cancel_order'),
                                                   icon: <ExclamationCircleOutlined />,
                                                   content: languageManager.getMessage(this.props.commonState, 'common.askOperationConfirm'),
                                                   okText: languageManager.getMessage(this.props.commonState, 'common.confirm'),
                                                   onOk: this.cancelOrder
                                                })
                                             }
                                          >Annulla ordine</Button>
                                       ) : null
                                    }
                                 </Space>
                              </Col>
                           </Row>
                        </Form.Item>
                     ) : (
                        <Form.Item
                           label="Stato ordine"
                        >
                           <Row gutter={8}>
                              <Col span={12}>
                                 <Input disabled value={_.isNil(this.state.formValues.order_status) ? null : this.state.formValues.order_status.name} />
                              </Col>
                           </Row>
                        </Form.Item>
                     )
                  }
                  {
                     this.state.isCustomer ? (
                        <Form.Item
                           label="Inviato alla logistica"
                        >
                           <Row gutter={8}>
                              <Col span={12}>
                                 <Input disabled value={_.isNil(this.state.formValues.sent_to_logistics) ? 'NO' : this.state.formValues.sent_to_logistics} />
                              </Col>
                              <Col span={12}>
                                 {
                                    _.isNil(this.state.formValues.sent_to_logistics) ? (
                                       <Button type='success' icon={<UploadOutlined />}
                                          disabled={_.isNil(this.state.itemId)}
                                          loading={this.state.loaderSendLogistics}
                                          onClick={() =>
                                             Modal.confirm({
                                                title: languageManager.getMessage(this.props.commonState, 'component.orders.send_to_logistics'),
                                                icon: <ExclamationCircleOutlined />,
                                                content: languageManager.getMessage(this.props.commonState, 'common.askOperationConfirm'),
                                                okText: languageManager.getMessage(this.props.commonState, 'common.confirm'),
                                                onOk: this.sendToLogistics
                                             })
                                          }
                                       >Invia alla logistica</Button>
                                    ) : null
                                 }
                              </Col>
                           </Row>
                        </Form.Item>
                     ) : null
                  }
                  {
                     this.state.isCustomer ? null : (
                        <Form.Item
                           label="Inviato a Quadrivium"
                        >
                           <Row gutter={8}>
                              <Col span={12}>
                                 <Input disabled value={_.isNil(this.state.formValues.sent_to_quadrivium) ? 'NO' : this.state.formValues.sent_to_quadrivium_human} />
                              </Col>
                              <Col span={12}>
                                 {
                                    _.isNil(this.state.formValues.sent_to_quadrivium) && _.includes([3, 4, 5], this.state.formValues.order_status_id) ? (
                                       <Button type='success' icon={<UploadOutlined />}
                                          loading={this.state.loaderSendQV}
                                          onClick={() =>
                                             Modal.confirm({
                                                title: languageManager.getMessage(this.props.commonState, 'component.orders.send_to_quadrivium'),
                                                icon: <ExclamationCircleOutlined />,
                                                content: languageManager.getMessage(this.props.commonState, 'common.askOperationConfirm'),
                                                okText: languageManager.getMessage(this.props.commonState, 'common.confirm'),
                                                onOk: this.sendToQuadrivium
                                             })
                                          }
                                       >Invia a Quadrivium</Button>
                                    ) : null
                                 }
                              </Col>
                           </Row>
                        </Form.Item>
                     )
                  }
                  <Divider />
                  {
                     this.state.isCustomer ?
                        <Form.Item name="supplier_id" noStyle><Input type="hidden" /></Form.Item> :
                        ([
                           <Form.Item
                              label="Fornitore"
                              name="supplier_id"
                           >
                              <Select
                                 showSearch
                                 placeholder="Scegli il fornitore"
                                 optionFilterProp="children"
                                 allowClear={true}
                                 onChange={this.handleSupplierChange}
                                 loading={this.state.loaderSupplier}
                                 disabled={this.state.formValues.order_status_id > 2}
                              // onSearch={onSearch}
                              // filterOption={(input, option) =>
                              //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              // }
                              >
                                 {
                                    this.state.supplierList.map((supplier) => {
                                       return <Option key={supplier.id} value={supplier.id}>{supplier.company}</Option>
                                    })
                                 }
                              </Select>
                           </Form.Item>,
                           this.state.actualSupplier ? (
                              this.state.extraChargesList.map(key => {
                                 return (
                                    <Form.Item key={key.id} label={key.name} style={{ marginBottom: 0 }}>
                                       <Input.Group compact>
                                          <Form.Item
                                             name={'extracharge_quantity_' + key.id}
                                          >
                                             <InputNumber placeholder='Quantità' style={{ width: '100px' }} onChange={this.calcExtracharges} />
                                          </Form.Item>
                                          <Form.Item
                                             name={'extracharge_' + key.id}
                                          >
                                             <InputNumber addonAfter="€" precision={2} style={{ width: '150px' }} onChange={this.calcExtracharges} />
                                          </Form.Item>
                                       </Input.Group>
                                    </Form.Item>
                                 )
                              })
                           ) : null
                        ])
                  }
                  <Form.Item
                     label="Cliente"
                     name="customer_id"
                  // initialvalue={this.state.formValues.customer_id}
                  >
                     <Select
                        showSearch
                        placeholder="Scegli il cliente"
                        optionFilterProp="children"
                        allowClear={true}
                        loading={this.state.loaderCustomer}
                        disabled={this.state.formValues.order_status_id > 2}
                     >
                        {
                           this.state.customerList.map((customer) => {
                              return <Option key={customer.id} value={customer.id}>{customer.company}</Option>
                           })
                        }
                     </Select>
                  </Form.Item>
               </Col>
            </Row>
            <Divider />
            <Space direction='vertical' size='small'>
               <Space direction='horizontal' size='small'>
                  <Button type='success'
                     icon={<PlusOutlined />}
                     onClick={() => this.showModalAddProduct()}
                     disabled={this.state.formValues.order_status_id > 3}
                  >Aggiungi prodotto</Button>
               </Space>
               <Table {...orderProductsTableLayout}
                  columns={orderProductsColumns}
                  dataSource={this.state.orderLines}
               />
               <Row gutter={32}>
                  <Col span={8}>
                     <Descriptions size='small' column={1} bordered contentStyle={{ fontWeight: 'bold' }}>
                        <Descriptions.Item label="Quantità">{this.state.orderTotalQuantity.toFixed(2)}</Descriptions.Item>
                     </Descriptions>
                  </Col>
                  <Col span={8}>
                     {/* <Form.Item
                        {...sideLayout}
                        label="Metodo di spedizione"
                        name="shipping_method_id"
                     >
                        <Select
                           showSearch
                           placeholder="Scegli il metodo di spedizione"
                           optionFilterProp="children"
                           allowClear={true}
                           onChange={this.handleShippingMethodChange}
                        >
                           {
                              !_.isNil(this.state.shippingMethodList) ? this.state.shippingMethodList.map((shipping) => {
                                 return <Option key={shipping.id} value={shipping.id}>{shipping.name}</Option>
                              }) : null
                           }
                        </Select>
                     </Form.Item> */}
                  </Col>
                  <Col span={8}>
                     <Descriptions size='small' column={1} bordered labelStyle={{ width: '200px' }} contentStyle={{ fontWeight: 'bold' }}>
                        <Descriptions.Item label="Valore prodotti">{this.state.orderTotalProducts.toFixed(2) + '€'}</Descriptions.Item>
                        {/* <Descriptions.Item label="Totale IVA">{this.state.orderTotalTax.toFixed(2) + '€'}</Descriptions.Item> */}
                        {/* <Descriptions.Item label="Totale ordine">{this.state.orderTotal.toFixed(2) + '€'}</Descriptions.Item> */}
                        <Descriptions.Item label="Totale supplementi">{this.state.extrachargesTotal.toFixed(2) + '€'}</Descriptions.Item>
                     </Descriptions>
                  </Col>
               </Row>
            </Space>
            {/* <Form.Item name="order_status_id" noStyle><Input type="hidden" value={this.state.formValues.order_status_id} /></Form.Item> */}
         </Form >
      )

      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="row">
                  <div className="col-sm-6">
                     <Title level={5}>{languageManager.getMessage(this.props.commonState, this.state.itemId ? 'component.orders.edit' : 'component.orders.create_new')}</Title>
                  </div>
                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                        <Space>
                        </Space>
                     </div>
                  </div>
               </div>
            </div>
            <div className="card-body">
               {PurchaseOrderForm}
               <ModalAddOrderProduct
                  productCascaderList={this.state.productCascaderListForSupplier}
                  visible={this.state.showModalAddProduct}
                  item={this.state.modalEditLineItem}
                  onClose={this.hideModalAddProduct}
                  onSubmit={(item) => this.handleAddProduct(item)} />
            </div>
            <div className="card-footer">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     <Space size='small'>
                        <Link to={{ pathname: "/orders/list" }} >
                           <Button icon={<DoubleLeftOutlined />}>
                              {languageManager.getMessage(this.props.commonState, 'common.back')}
                           </Button>
                        </Link>
                     </Space>
                  </div>

                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                        <Space>
                           <Button onClick={this.handleResetForm} disabled={this.state.formLoader}>Annulla</Button>
                           <Button form="OrderCreateForm" type="primary" htmlType="submit"
                              loading={this.state.formLoader}
                              disabled={this.state.errorInternalCode || this.state.errorSupplierCode}>Salva</Button>
                           <Button form="OrderCreateForm" type="primary" htmlType="submit" onClick={this.handleSaveAndClose}
                              loading={this.state.formLoader}
                              disabled={this.state.errorInternalCode || this.state.errorSupplierCode}>Salva e chiudi</Button>
                           {
                              this.state.isCustomer && _.isNil(this.state.formValues.sent_to_logistics) ? (
                                 <Button type='danger' icon={<CloseOutlined />}
                                    loading={this.state.loaderCancelOrder}
                                    title="Annulla ordine"
                                    disabled={_.isNil(this.state.itemId)}
                                    onClick={() =>
                                       Modal.confirm({
                                          title: languageManager.getMessage(this.props.commonState, 'component.orders.cancel_order'),
                                          icon: <ExclamationCircleOutlined />,
                                          content: languageManager.getMessage(this.props.commonState, 'common.askOperationConfirm'),
                                          okText: languageManager.getMessage(this.props.commonState, 'common.confirm'),
                                          onOk: this.cancelOrder
                                       })
                                    }
                                 />
                              ) : null
                           }
                        </Space>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      );

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>

      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
};

export default connect(mapStateToProps)(PurchaseOrderEdit);