import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import languageManager from '../../../../services/languageManager'

import { Row, Col, Card, Form, Input, InputNumber, Select, Switch, Divider } from 'antd'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'

const SupplierExtraChargesForm = (props) => {
    const [layout, setLayout] = useState(props.layout)
    const [keys, setKeys] = useState(props.keys)
    const [values, setValues] = useState(props.values)

    const submitForm = (values) => {
        props.submitForm(values)
    }

    return (
        <Form
            {...layout}
            id="supplierExtraChargesForm"
            name="supplierExtraChargesForm"
            onFinish={submitForm}
        >
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <Card
                        type="inner"
                        title="Ordini in entrata">
                        {
                            keys.filter(x => x.apply_on_charging).map(key => {
                                return (
                                    <Form.Item
                                        label={key.name}
                                        name={key.id}
                                        initialValue={_.isNil(values.find(obj => { return obj.extra_charge_id == key.id })) ? null : values.find(obj => { return obj.extra_charge_id == key.id }).cost_total}
                                    >
                                        <InputNumber addonAfter="€" />
                                    </Form.Item>
                                )
                            })
                        }
                    </Card>
                </Col>
                <Col span={12}>
                    <Card
                        type="inner"
                        title="Ordini in uscita">
                        {
                            keys.filter(x => !x.apply_on_charging).map(key => {
                                return (
                                    <Form.Item
                                        label={key.name}
                                        name={key.id}
                                        initialValue={_.isNil(values.find(obj => { return obj.extra_charge_id == key.id })) ? null : values.find(obj => { return obj.extra_charge_id == key.id }).cost_total}
                                    >
                                        <InputNumber addonAfter="€" />
                                    </Form.Item>
                                )
                            })
                        }
                    </Card>
                </Col>
            </Row>
        </Form>
    )
}

const mapStateToProps = state => {
    return {
        commonState: state.commonState,
    }
}

export default connect(mapStateToProps)(SupplierExtraChargesForm)