import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import { Typography, Input, InputNumber, Select, TreeSelect, Switch, Button, Space, notification } from 'antd'
import { InfoCircleOutlined, DoubleLeftOutlined } from '@ant-design/icons'

import languageManager from '../../../services/languageManager'
import SupplierForm from './components/SupplierForm'
import * as storeActions from './store/actions/suppliers'

import Loader from '../../common/PageElements/Loader'
import Reloader from '../../common/PageElements/Reloader'

class SuppliersEdit extends Component {

   state = {
      getError: false,
      pageLoader: true,
      action: undefined,
      itemId: undefined,
      saveAndClose: false,
      formLoader: false,
      formValues: {},
      groupsList: [],
      componentLanguage: this.props.authState.defaultLanguage
   };

   // static getDerivedStateFromProps(props, state) {
   //    if (props.action === 'edit') {
   //       const formValues = {
   //          id: props.itemData.id,
   //          name: props.itemData.name.it,
   //          percentage: props.itemData.percentage,
   //          language: 'it'
   //       }
   //       return updateObject(state, { formValues: formValues });
   //       // state.initial_values.name = props.itemData.t_name;
   //       // state.initial_values.percentage = props.itemData.percentage;
   //    }

   //    return state;
   // }

   setForm = (response, lang = this.state.componentLanguage) => {
      const values = {
         internal_code: response.internal_code,
         quadrivium_code: response.quadrivium_code,
         company_name: response.company,
         vat: response.vat,
         fiscal_code: response.fiscal_code,
         sdi: response.sdi,
         email: response.email,
         pec: response.pec,
         phone: response.phone,
         address_1: response.address_1,
         address_2: response.address_2,
         postal_code: response.postal_code,
         city: response.city,
         state: response.state,
         country: response.country,
         id: response.id,
         language: lang
      }
      return values;
   }

   getItem = (id) => {
      return storeActions.getItem(id)
         .then(response => {
            console.log(response);
            try {
               const updatedValues = this.setForm(response.data);
               // console.log(updatedValues)
               this.setState({ response: response.data, formValues: updatedValues });
            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
            }
         }).catch((e) => {
            this.setState({ getError: true });
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   componentDidMount() {
      if (this.props.match.params.id) {
         const itemId = this.props.match.params.id
         Promise.all([
            this.getItem(itemId),
            // this.getComplements()
         ]).then(() => {
            this.setState({
               action: 'edit',
               itemId: itemId,
               pageLoader: false
            });
         });
      }
      else {
         const formValues = {
            language: this.state.componentLanguage,
         }
         Promise.all([
            // this.getComplements()
         ]).then(() => {
            this.setState({
               formValues: formValues,
               action: 'insert',
               pageLoader: false
            });
         })
      }
   }

   handleSaveAndClose = () => {
      this.setState({ saveAndClose: true });
      console.log(this.state);
   }

   // onParentChange = value => {
   //    console.log(value);
   //    this.setState({ value });
   // };

   onFinish = values => {
      this.setState({ formLoader: true });
      values.visible = true;
      storeActions.createOrUpdate(values)
         .then(response => {
            console.log(response);
            try {
               if (this.state.saveAndClose) {
                  this.props.history.goBack();
               }
               const updatedValues = this.setForm(response.data);
               this.setState({ response: response.data, itemId: response.data.id, action: 'edit', formValues: updatedValues, formLoader: false });

               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
                  description: languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               this.setState({ formLoader: false });
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
            }

         }).catch((e) => {
            this.setState({ formLoader: false });
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
         });
   };

   onFinishFailed = errorInfo => {
      notification.open({
         message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
         description: languageManager.getMessage(this.props.commonState, 'notification.error.formInvalid'),
         icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      })
   };

   onVisibleSwitchChange = (checked) => {
      let newFormValues = { ...this.state.formValues }
      newFormValues.is_visible = checked;
      this.setState({ formValues: newFormValues });
   }

   setDefaultLanguageHandler = (value) => {
      console.log(value);
      const updatedValues = this.setForm(this.state.response, value);
      this.setState({ formValues: updatedValues, componentLanguage: value })
   }

   render() {
      if (this.state.getError) return <Reloader />;
      if (this.state.pageLoader) return <Loader />;
      const { Title } = Typography
      const formLayout = {
         labelCol: { span: 8 },
         wrapperCol: { span: 14 },
      };
      const tailLayout = {
         wrapperCol: { offset: 8, span: 16 },
      };
      const { TextArea } = Input;
      const { Option } = Select;

      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     <Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.suppliers.title')}</Title>
                  </div>
                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                        <Space>
                           {
                              !_.isNil(this.props.authState.userData.languages) && this.props.authState.userData.languages.length > 1 ? (
                                 <Select
                                    showSearch
                                    className="width-70px-I"
                                    placeholder="Seleziona la lingua"
                                    defaultValue={this.state.componentLanguage.toUpperCase()}
                                    optionFilterProp="children"
                                    onChange={this.setDefaultLanguageHandler}
                                 >
                                    {
                                       this.props.authState.userData.languages.map((lang) => {
                                          return <Option key={lang.value} value={lang.value}>{lang.label}</Option>
                                       })
                                    }
                                 </Select>
                              ) : null
                           }
                        </Space>
                     </div>
                  </div>
               </div>
            </div>
            <div className="card-body">
               {this.state.pageLoader ? <Loader /> : (
                  <SupplierForm
                     layout={formLayout}
                     submitForm={this.onFinish}
                     values={this.state.formValues}
                  />)}
            </div>
            <div className="card-footer">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     <Space size='small'>
                        <Link to={{ pathname: "/suppliers/list" }} >
                           <Button icon={<DoubleLeftOutlined />}>
                              {languageManager.getMessage(this.props.commonState, 'common.back')}
                           </Button>
                        </Link>
                     </Space>
                  </div>

                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">

                        <Space>
                           <Button form="supplierForm" htmlType="button" disabled={this.state.formLoader}>Annulla</Button>
                           <Button form="supplierForm" type="primary" htmlType="submit" loading={this.state.formLoader}>Salva</Button>
                           <Button form="supplierForm" type="primary" htmlType="submit" onClick={this.handleSaveAndClose} loading={this.state.formLoader}>Salva e chiudi</Button>
                        </Space>

                     </div>
                  </div>
               </div>
            </div>
         </div>
      );

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>

      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(SuppliersEdit);