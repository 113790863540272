import React, { Component } from "react"
import { withRouter, Link } from "react-router-dom"
import _ from 'lodash'

import languageManager from '../../services/languageManager'

import { Menu } from 'antd'

class Sidebar extends Component {
   state = {
      ...this.props,
      menuItems: [],
      openKeys: undefined,
      selectedKeys: undefined,
   }

   componentDidMount() {
      Promise.all([
         this.prepareMenuItems()
      ]).then(() => {
         this.initMenu()
      })
   }

   prepareMenuItems = () => {
      let menu = []

      menu.push({
         key: 'dashboard',
         label: <Link to="/dashboard">{languageManager.getMessage(this.props.commonState, 'component.dashboard.title')}</Link>,
         path: '/dashboard',
         disabled: false
      })
      // menu.push({ key: 'main', label: 'Main', children: main })
      
      let master_data = []
      master_data.push({
         key: 'suppliers',
         label: <Link to='/suppliers/list'>{languageManager.getMessage(this.props.commonState, 'component.suppliers.title')}</Link>,
         path: '/suppliers',
         parent: 'master_data',
      })
      master_data.push({
         key: 'customers',
         label: <Link to='/customers/list'>{languageManager.getMessage(this.props.commonState, 'component.customers.title')}</Link>,
         path: '/customers',
         parent: 'master_data',
      })
      menu.push({ key: 'master_data', label: 'Anagrafiche', children: master_data })
      
      let catalog = []
      catalog.push({
         key: 'categories',
         label: <Link to="/categories/list">{languageManager.getMessage(this.props.commonState, 'nav.categories')}</Link>,
         path: '/categories',
         parent: 'catalog',
      })
      catalog.push({
         key: 'products',
         label: <Link to="/products/list">{languageManager.getMessage(this.props.commonState, 'nav.products.in_catalog')}</Link>,
         path: '/products',
         parent: 'catalog',
      })
      // catalog.push({
      //    key: 'search_products',
      //    label: <Link to="/search_products">{languageManager.getMessage(this.props.commonState, 'nav.search_products')}</Link>,
      //    path: '/search_products',
      //    parent: 'catalog',
      // })
      menu.push({ key: 'catalog', label: 'Catalogo', children: catalog })
      
      let purchaseOrders = []
      purchaseOrders.push({
         key: 'purchase_order_list',
         label: <Link to="/purchase_orders/list">{languageManager.getMessage(this.props.commonState, 'Entrata merce')}</Link>,
         path: '/purchase_orders/list',
         parent: 'purchase_orders',
      })
      purchaseOrders.push({
         key: 'order_list',
         label: <Link to="/orders/list">{languageManager.getMessage(this.props.commonState, 'Uscita merce')}</Link>,
         path: '/orders/list',
         parent: 'purchase_orders',
      })
      // purchaseOrders.push({
      //    key: 'pending_orders',
      //    label: <Link to="/purchase_orders/list/pending">{languageManager.getMessage(this.props.commonState, 'nav.purchase_orders.pending')}</Link>,
      //    path: '/purchase_orders/list/pending',
      //    parent: 'purchase_orders',
      // })
      // purchaseOrders.push({
      //    key: 'closing_orders',
      //    label: <Link to="/purchase_orders/manual_updates/list">{languageManager.getMessage(this.props.commonState, 'nav.closing_po')}</Link>,
      //    path: '/purchase_orders/manual_updates/list',
      //    parent: 'purchase_orders',
      // })
      // purchaseOrders.push({
      //    key: 'import_file',
      //    label: <Link to="/test/import_file">{languageManager.getMessage(this.props.commonState, 'nav.import_file')}</Link>,
      //    path: '/test/import_file',
      //    parent: 'purchase_orders',
      // })
      menu.push({ key: 'purchase_orders', label: 'Ordini', children: purchaseOrders })

      let configuration = []
      // configuration.push({
      //    key: 'marketplaces',
      //    label: <Link to='/configuration/marketplaces/list'>{languageManager.getMessage(this.props.commonState, 'nav.marketplaces')}</Link>,
      //    path: '/configuration/marketplaces',
      //    parent: 'configuration',
      // })
      configuration.push({
         key: 'tax_rates',
         label: <Link to='/configuration/tax_rates/list'>{languageManager.getMessage(this.props.commonState, 'component.tax_rates.title')}</Link>,
         path: '/configuration/tax_rates',
         parent: 'configuration',
      })
      configuration.push({
         key: 'extra_charges',
         label: <Link to='/configuration/extra_charges/list'>{languageManager.getMessage(this.props.commonState, 'component.extra_charges.title')}</Link>,
         path: '/configuration/extra_charges',
         parent: 'configuration',
      })
      menu.push({ key: 'configuration', label: 'Configurazione', children: configuration })

      let menuUser = [];

      menuUser.push({
         key: 'dashboard',
         label: <Link to="/dashboard">{languageManager.getMessage(this.props.commonState, 'component.dashboard.title')}</Link>,
         path: '/dashboard',
         disabled: false
      })
      let user_master_data = []
      user_master_data.push({
         key: 'customers',
         label: <Link to='/customers/list'>{languageManager.getMessage(this.props.commonState, 'component.customers.title')}</Link>,
         path: '/customers',
         parent: 'master_data',
      })
      menuUser.push({ key: 'master_data', label: 'Anagrafiche', children: user_master_data })
      menuUser.push({ key: 'catalog', label: 'Catalogo', children: catalog })
      
      let orders = []
      orders.push({
         key: 'purchase_order_list',
         label: <Link to="/purchase_orders/list">{languageManager.getMessage(this.props.commonState, 'Entrata merce in logistica')}</Link>,
         path: '/purchase_orders/list',
         parent: 'orders',
      })
      orders.push({
         key: 'order_list',
         label: <Link to="/orders/list">{languageManager.getMessage(this.props.commonState, 'Uscita merce da logistica')}</Link>,
         path: '/orders/list',
         parent: 'orders',
      })
      menuUser.push({ key: 'orders', label: 'Ordini', children: orders })
      
      return this.setState({ menuItems: _.isNil(this.props.authState.userData.account.parent_account_id) ? menu : menuUser })
   }

   initMenu() {
      // console.log(this.props.location.pathname, this.props.location.pathname.split('/'))
      // const parentDropdown = this.props.location.pathname.split('/')[1]
      // const selectedItem = this.state.menuItems.find(x => x.path == this.props.location.pathname)
      const selectedItem = searchKeyFromPath(this.props.location.pathname, this.state.menuItems)
      // console.log('INIT MENU', this.props.location.pathname, selectedItem)
      let selectedKeys = []
      let parentDropdown = []
      if(selectedItem) {
         selectedKeys.push(selectedItem.key)
         parentDropdown.push(selectedItem.parent)
      }
      // console.log('SELECTED', selectedKeys)
      this.setState({ openKeys: parentDropdown, selectedKeys: selectedKeys })
   }

   onOpenChange = (openKeys) => {
      this.setState({ openKeys: openKeys })
   }
   // onMenuClick = (item) => {
   //    console.log(item, item.key)
   //    let menuItem = searchPathFromKey(item.key, this.state.menuItems)
   //    console.log(menuItem)
   //    if (!_.isNil(menuItem)) {
   //       this.props.history.push(menuItem.path)
   //    }
   // }

   render() {
      if (_.isUndefined(this.state.openKeys) || _.isUndefined(this.state.selectedKeys)) return null
      // console.log('RENDER', this.state.selectedKeys, this.state.openKeys)
      return (
         <React.Fragment>
            <Menu
               // defaultSelectedKeys={['dashboard_ordini']}
               selectedKeys={this.state.selectedKeys}
               openKeys={this.state.openKeys}
               onOpenChange={this.onOpenChange}
               // onClick={this.onMenuClick} 
               mode="inline"
               theme="dark"
               // inlineCollapsed={collapsed}
               items={this.state.menuItems}
               style={{ height: '100%' }}
            />
         </React.Fragment>
      );
   }
}

function searchPathFromKey(key, array) {
   var result
   array.some(o => (o.key === key && (result = o)) || (result = searchPathFromKey(key, o.children || [])))
   return result
}

function searchKeyFromPath(path, array) {
   var result
   array.some(o => (!_.isNil(o.path) && path.includes(o.path) && (result = o)) || (result = searchKeyFromPath(path, o.children || [])))
   return result
}

export default withRouter(Sidebar);
