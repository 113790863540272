import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash'

import { Row, Col, Typography, Button, Space, notification, Divider } from 'antd'
import { InfoCircleOutlined, CheckOutlined, CloseOutlined, PlusSquareOutlined } from '@ant-design/icons'

import languageManager from '../../../services/languageManager'
import SupplierExtraChargesForm from './components/SupplierExtraChargesForm'
import SupplierDataIntro from './components/SupplierDataIntro'
import * as storeActions from './store/actions/suppliers'

import Loader from '../../common/PageElements/Loader'
import Reloader from '../../common/PageElements/Reloader'

class SuppliersConfig extends Component {

   state = {
      getError: false,
      pageLoader: true,
      action: undefined,
      itemId: undefined,
      saveAndClose: false,
      formLoader: false,
      formValues: {},
      groupsList: [],
      componentLanguage: this.props.authState.defaultLanguage
   };

   // static getDerivedStateFromProps(props, state) {
   //    if (props.action === 'edit') {
   //       const formValues = {
   //          id: props.itemData.id,
   //          name: props.itemData.name.it,
   //          percentage: props.itemData.percentage,
   //          language: 'it'
   //       }
   //       return updateObject(state, { formValues: formValues });
   //       // state.initial_values.name = props.itemData.t_name;
   //       // state.initial_values.percentage = props.itemData.percentage;
   //    }

   //    return state;
   // }

   setForm = (response, lang = this.state.componentLanguage) => {
      const values = {
         internal_code: response.internal_code,
         company_name: response.company,
         vat: response.vat,
         fiscal_code: response.fiscal_code,
         sdi: response.sdi,
         email: response.email,
         pec: response.pec,
         phone: response.phone,
         address_1: response.address_1,
         address_2: response.address_2,
         postal_code: response.postal_code,
         city: response.city,
         state: response.state,
         country: response.country,
         id: response.id,
         language: lang
      }
      return values;
   }

   getItem = (id) => {
      return storeActions.getItem(id)
         .then(response => {
            console.log(response);
            try {
               // const updatedValues = this.setForm(response.data);
               // console.log(updatedValues)
               // this.setState({ response: response.data, formValues: updatedValues });

               this.setState({ response: response, supplier: response.data });
            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
            }
         }).catch((e) => {
            this.setState({ getError: true });
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   getExtraCharges = (id) => {
      return storeActions.getExtraCharges(id)
         .then(response => {
            console.log(response);
            try {
               // const updatedValues = this.setForm(response.data);
               // console.log(updatedValues)
               // this.setState({ response: response.data, formValues: updatedValues });

               this.setState({ supplier_extra_charges: response.data });
            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
            }
         }).catch((e) => {
            this.setState({ getError: true });
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   getComplements = () => {
      return storeActions.getComplements()
         .then(response => {
            console.log(response);
            try {
               this.setState({
                  response: response,
                  tax_rates: response.taxRatesList,
                  extra_charges: response.extraChargesList
               });

            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
               if (this.props.commonState.debug) console.log(e);
            }

         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   componentDidMount() {
      const itemId = this.props.match.params.id
      console.log('ItemId', itemId)
      Promise.all([
         this.getItem(itemId),
         this.getExtraCharges(itemId),
         this.getComplements()
      ]).then(() => {
         this.setState({
            action: 'edit',
            itemId: itemId,
            pageLoader: false
         });
      });
   }

   handleSaveAndClose = () => {
      this.setState({ saveAndClose: true });
      console.log(this.state);
   }

   // onParentChange = value => {
   //    console.log(value);
   //    this.setState({ value });
   // };

   onFinish = values => {
      console.log(values);
      this.setState({ formLoader: true });
      storeActions.saveExtraCharges(this.state.itemId, values)
         .then(response => {
            console.log(response);
            this.setState({ formLoader: false });
            try {
               if (this.state.saveAndClose) {
                  this.props.history.goBack();
               }

               notification.success({
                  message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.updateOk'),
               })
            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
            }

         }).catch((e) => {
            this.setState({ formLoader: false });
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
         });
   };

   render() {
      if (this.state.getError) return <Reloader />;
      if (this.state.pageLoader) return <Loader />;
      const { Title, Text } = Typography
      const formLayout = {
         labelCol: { span: 10 },
         wrapperCol: { span: 14 },
      };

      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     <Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.suppliers.title')}</Title>
                  </div>
                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                     </div>
                  </div>
               </div>
            </div>
            <div className="card-body">
               <Space direction='vertical' size='middle'>
                  <Row gutter={[16, 16]}>
                     <Col span={24}>
                        <SupplierDataIntro item={this.state.supplier} />
                     </Col>
                  </Row>
                  <SupplierExtraChargesForm
                     layout={formLayout}
                     submitForm={this.onFinish}
                     keys={this.state.extra_charges}
                     values={this.state.supplier_extra_charges}
                  />
               </Space>
            </div>
            <div className="card-footer">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     <Space>
                        <Button
                           size='large'
                           onClick={() => { this.props.history.goBack() }}
                        >
                           <i className="mdi mdi-arrow-left-drop-circle-outline mr-1"></i>
                           {languageManager.getMessage(this.props.commonState, 'common.back')}
                        </Button>
                     </Space>
                  </div>

                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                        <Space>
                           <Button form="supplierExtraChargesForm" htmlType="button" disabled={this.state.formLoader}>Annulla</Button>
                           <Button form="supplierExtraChargesForm" type="primary" htmlType="submit" loading={this.state.formLoader}>Salva</Button>
                           <Button form="supplierExtraChargesForm" type="primary" htmlType="submit" onClick={this.handleSaveAndClose} loading={this.state.formLoader}>Salva e chiudi</Button>
                        </Space>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      );

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>

      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(SuppliersConfig);