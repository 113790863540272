import React from "react"
import { Redirect } from "react-router-dom"

// Pages Component

// Authentication related pages
import Login from "../components/authentication/PageLogin"
import Logout from "../components/authentication/Logout"
// import Register from "../pages/Authentication/Register"
// import ForgetPwd from "../pages/Authentication/FogetPassword"
import UnauthorizedPage from "../components/authentication/403"
import PasswordEdit from "../components/user/PasswordEdit"

import Dashboard from "../components/dashboard/Dashboard"

import CustomersList from "../components/master_data/customers/CustomersList"
import CustomersEdit from "../components/master_data/customers/CustomersEdit"

import SuppliersList from "../components/master_data/suppliers/SuppliersList"
import SuppliersEdit from "../components/master_data/suppliers/SuppliersEdit"
import SuppliersConfig from "../components/master_data/suppliers/SuppliersConfig"



// import BrandsList from "../components/catalogue/brands/BrandsList"
// import BrandsEdit from "../components/catalogue/brands/BrandsEdit"

// import CataloguesList from "../components/catalogue/catalogues/CataloguesList"
// import CataloguesEdit from "../components/catalogue/catalogues/CataloguesEdit"

import CategoriesList from "../components/catalogue/categories/CategoriesList"
import CategoriesOrder from "../components/catalogue/categories/CategoriesOrder"
import CategoriesEdit from "../components/catalogue/categories/CategoriesEdit"

import ProductsList from "../components/catalogue/products/ProductsList"
// import ProductsListNZTAsin from "../components/catalogue/products/ProductsListNZTAsin"
// import ProductsListNOAsin from "../components/catalogue/products/ProductsListNOAsin"
// import ProductsListMissingParity from "../components/catalogue/products/ProductsListMissingParity"
// import ProductsListNOVendorCodes from "../components/catalogue/products/ProductsListNOVendorCodes"
// import ProductsImport from "../components/catalogue/products/ProductsImport"
import ProductsEdit from "../components/catalogue/products/ProductsEdit"

// import SearchProducts from "../components/catalogue/search_products/SearchProducts"

// import WarehousesList from "../components/stocks/stock_warehouses/WarehousesList"
// import StockWarehousesList from "../components/stocks/stock_warehouses/StockWarehousesList"
// import StockSuppliersList from "../components/stocks/stock_suppliers/StockSuppliersList"

// import AmazonMapsList from "../components/catalogue/model_maps/AmazonMapsList"
// import AmazonMapsImport from "../components/catalogue/model_maps/AmazonMapsImport"

import PurchaseOrdersList from "../components/orders/purchase_orders/PurchaseOrdersList"
import PurchaseOrderDetails from "../components/orders/purchase_orders/PurchaseOrderDetails"
import PurchaseOrderEdit from "../components/orders/purchase_orders/PurchaseOrderEdit"

import OrdersList from "../components/orders/orders/OrdersList"
import OrderDetails from "../components/orders/orders/OrderDetails"
import OrderEdit from "../components/orders/orders/OrderEdit"

// import VendorOrdersList from "../components/orders/vendor_orders/VendorOrdersList"
// import VendorOrderImport from "../components/orders/vendor_orders/VendorOrderImport"
// import VendorOrdersEdit from "../components/orders/vendor_orders/VendorOrdersEdit"
// import VendorOrderDetails from "../components/orders/vendor_orders/VendorOrderDetails"
// import VendorOrderLinesImport from "../components/orders/vendor_orders/VendorOrderLinesImport"

// import ExpiringOrdersList from "../components/orders/expiring_orders/ExpiringOrdersList"
// import ExpiringOrdersEdit from "../components/orders/expiring_orders/ExpiringOrdersEdit"
// import ExpiringOrderDetails from "../components/orders/expiring_orders/ExpiringOrderDetails"

// import ClosingOrdersList from "../components/orders/closing_orders/ClosingOrdersList"
// import ClosingOrderDetails from "../components/orders/closing_orders/ClosingOrderDetails"

// import SpecificsList from "../components/catalogue/specifics/SpecificsList"
// import SpecificsEdit from "../components/catalogue/specifics/SpecificsEdit"

// import SpecificValuesList from "../components/catalogue/specifics/SpecificValuesList"
// import SpecificValuesEdit from "../components/catalogue/specifics/SpecificValuesEdit"

// import VariationsList from "../components/catalogue/variations/VariationsList"
// import VariationsEdit from "../components/catalogue/variations/VariationsEdit"

// import VariationValuesList from "../components/catalogue/variations/VariationValuesList"
// import VariationValuesEdit from "../components/catalogue/variations/VariationValuesEdit"

// import ChargebacksLink from "../components/chargebacks/ChargebacksLink"

// import GroupSizes from "../components/data_processing/GroupSizes"
// import SearchAsinsInCatalog from "../components/data_processing/SearchAsinsInCatalog"

// // import Dashboard from "../pages/Dashboard"
// import MarketplacesList from "../components/configuration/marketplace/MarketplacesList"
// import MarketplacesEdit from "../components/configuration/marketplace/MarketplacesEdit"
// import MarketplacesConfig from "../components/configuration/marketplace/MarketplacesConfig"

// import VendorCodesList from "../components/configuration/vendor_codes/VendorCodesList"
// import VendorCodesEdit from "../components/configuration/vendor_codes/VendorCodesEdit"

// import PriceRulesList from "../components/configuration/priceRules/PriceRulesList"
// import PriceRulesEdit from "../components/configuration/priceRules/PriceRulesEdit"

import TaxRatesList from "../components/configuration/taxes/TaxRatesList"
import TaxRatesEdit from "../components/configuration/taxes/TaxRatesEdit"

import ExtraChargesList from "../components/configuration/extra_charges/ExtraChargesList"
import Unauthorized from "../components/error/Unauthorized"

// import ProgressivesList from "../components/operations/progressives/ProgressivesList"
// import SupplierConfirmationsList from "../components/operations/supplierConfirmations/SupplierConfirmationsList"
// import SupplierConfirmationDetails from "../components/operations/supplierConfirmations/SupplierConfirmationDetails"

// import UniqueFilesRiaList from "../components/unique_files/ria/UniqueFilesRiaList"
// import UniqueFileRiaDetails from "../components/unique_files/ria/UniqueFileRiaDetails"
// import UniqueFilesSeasonalList from "../components/unique_files/seasonal/UniqueFilesSeasonalList"
// import UniqueFileSeasonalDetails from "../components/unique_files/seasonal/UniqueFileSeasonalDetails"

// import AnalysisCategories from "../components/analysis/Categories"
// import AnalysisEanAsin from "../components/analysis/ean_asin/AnalysisEanAsin"
// import AsinAvailability from "../components/analysis/asin_availability/AsinAvailability"

// import ReadEdiFile from "../components/edi/ReadEdiFile"

// import TestImportFile from "../components/test/import/ImportFile"

// import ManualUpdatesList from "../components/orders/manual_updates/ManualUpdatesList"
// import ManualUpdateDetails from "../components/orders/manual_updates/ManualUpdateDetails"

const authProtectedRoutes = [
   { path: "/unauthorized", component: UnauthorizedPage },
   
   // User
   { path: "/user/edit_password", component: PasswordEdit },
   
   // Dashboard
   { path: "/dashboard", component: Dashboard },
   
   { path: "/customers/list", component: CustomersList },
   { path: "/customers/edit/:id?", component: CustomersEdit },
   
   { path: "/suppliers/list", component: SuppliersList },
   { path: "/suppliers/edit/:id?", component: SuppliersEdit },
   { path: "/suppliers/config/:id", component: SuppliersConfig },
   
   // // Brands
   // { path: "/brands/list", component: BrandsList },
   // { path: "/brands/edit/:id?", component: BrandsEdit },
   
   // // Catalogues
   // { path: "/catalogues/list", component: CataloguesList },
   // { path: "/catalogues/edit/:id?", component: CataloguesEdit },
   
   // Categories
   { path: "/categories/list/:parent?", component: CategoriesList },
   { path: "/categories/order", component: CategoriesOrder },
   { path: "/categories/edit/:id?", component: CategoriesEdit },
   
   // Products
   { path: "/products/list/:status?", component: ProductsList },
   // { path: "/products/import/", component: ProductsImport },
   { path: "/products/edit/:id?", component: ProductsEdit },

   // Purchase Orders
   { path: "/purchase_orders/list/:status?", component: PurchaseOrdersList },
   { path: "/purchase_orders/details/:id", component: PurchaseOrderDetails },
   { path: "/purchase_orders/edit/:id?", component: PurchaseOrderEdit },

   // Orders
   { path: "/orders/list/:status?", component: OrdersList },
   { path: "/orders/details/:id?", component: OrderDetails },
   { path: "/orders/edit/:id?", component: OrderEdit },
   
   // // Search products
   // { path: "/search_products/", component: SearchProducts },
   
   // // Stocks
   // { path: "/warehouses/list", component: WarehousesList },
   // { path: "/warehouses/stocks/:warehouse_id?", component: StockWarehousesList },
   // { path: "/suppliers/stocks/:supplier_id?", component: StockSuppliersList },
   
   // // Amazon Maps
   // { path: "/model_maps/list", component: AmazonMapsList },
   // { path: "/model_maps/import", component: AmazonMapsImport },
   
   // // Purchase Orders
   // { path: "/purchase_orders/list/:status?", component: PurchaseOrdersList },
   // { path: "/purchase_orders/import/", component: PurchaseOrdersImport },
   // { path: "/purchase_orders/edit/:id?", component: PurchaseOrdersEdit },
   // { path: "/purchase_orders/details/:id", component: PurchaseOrderDetails },
   // { path: "/purchase_order_lines/import/:id", component: PurchaseOrderLinesImport },
   
   // // Purchase Order Manual Updates
   // { path: "/purchase_orders/manual_updates/list/", component: ManualUpdatesList },
   // { path: "/purchase_orders/manual_updates/details/:id", component: ManualUpdateDetails },
   
   // // Expiring Orders
   // { path: "/expiring_orders/list/", component: ExpiringOrdersList },
   // { path: "/expiring_orders/edit/:id?", component: ExpiringOrdersEdit },
   // { path: "/expiring_orders/details/:id", component: ExpiringOrderDetails },
   
   // // Vendor Orders
   // { path: "/vendor_orders/list/", component: VendorOrdersList },
   // { path: "/vendor_orders/import/", component: VendorOrderImport },
   // { path: "/vendor_orders/edit/:id?", component: VendorOrdersEdit },
   // { path: "/vendor_orders/details/:id", component: VendorOrderDetails },
   // { path: "/vendor_order_lines/import/:id", component: VendorOrderLinesImport },
   
   // // Closing Orders
   // { path: "/closing_orders/list/", component: ClosingOrdersList },
   // { path: "/closing_orders/details/:id", component: ClosingOrderDetails },
   
   // // Specifics
   // { path: "/specifics/list", component: SpecificsList },
   // { path: "/specifics/edit/:id?", component: SpecificsEdit },
   // { path: "/specifics/values/list/:parent", component: SpecificValuesList },
   // { path: "/specifics/values/edit/:parent/:id?", component: SpecificValuesEdit },
   
   // // Variations
   // { path: "/variations/list", component: VariationsList },
   // { path: "/variations/edit/:id?", component: VariationsEdit },
   // { path: "/variations/values/list/:parent", component: VariationValuesList },
   // { path: "/variations/values/edit/:parent/:id?", component: VariationValuesEdit },
   
   // // Chargebacks
   // { path: "/chargebacks/link", component: ChargebacksLink },
   
   // // Data processing
   // { path: "/dataprocessing/group_sizes", component: GroupSizes },
   // { path: "/dataprocessing/search_asins", component: SearchAsinsInCatalog },
   
   // // Marketplace
   // { path: "/configuration/marketplaces/list", component: MarketplacesList },
   // { path: "/configuration/marketplaces/edit/:id?", component: MarketplacesEdit },
   // { path: "/configuration/marketplaces/config/:id", component: MarketplacesConfig },
   
   // // Vendor Codes
   // { path: "/configuration/vendor_codes/list", component: VendorCodesList },
   // { path: "/configuration/vendor_codes/edit/:id?", component: VendorCodesEdit },
   
   // // Price Rules
   // { path: "/price_rules/list/", component: PriceRulesList },
   // { path: "/price_rules/edit/:id?", component: PriceRulesEdit },
   
   // Tax Rates
   { path: "/configuration/tax_rates/list", component: TaxRatesList },
   { path: "/configuration/tax_rates/edit/:id?", component: TaxRatesEdit },
   
   // Extra Charges
   { path: "/configuration/extra_charges/list", component: ExtraChargesList },
   
   // // Operations
   // { path: "/operations/progressives/list", component: ProgressivesList },
   // { path: "/operations/supplier_confirmations/list", component: SupplierConfirmationsList },
   // { path: "/operations/supplier_confirmations/details/:id", component: SupplierConfirmationDetails },
   
   // // Unique files
   // { path: "/unique_files/ria/list", component: UniqueFilesRiaList },
   // { path: "/unique_files/ria/details/:unique_file_id/:brand_id", component: UniqueFileRiaDetails },
   // { path: "/unique_files/seasonal/list", component: UniqueFilesSeasonalList },
   // { path: "/unique_files/seasonal/details/:unique_file_id/:brand_id", component: UniqueFileSeasonalDetails },
   
   // // Analysis
   // { path: "/analysis/categories", component: AnalysisCategories },
   // { path: "/analysis/ean_asin", component: AnalysisEanAsin },
   // { path: "/analysis/asin_availability", component: AsinAvailability },
   
   // // Tests
   // { path: "/test/import_file", component: TestImportFile },
   
   // // EDI
   // { path: "/edi/read", component: ReadEdiFile },
   
   { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> }
]

const publicRoutes = [
   { path: "/logout", component: Logout },
   { path: "/login", component: Login },
]

export { authProtectedRoutes, publicRoutes }