import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Descriptions } from 'antd'

class SupplierDataIntro extends Component {
   state = {
      ...this.props,
      itemValues: [],
      componentLoader: true,
      componentLanguage: this.props.authState.defaultLanguage
   }
   formRef = React.createRef();

   componentDidMount() {
      // console.log('Component did mount')
      Promise.all([
         // this.getItem()
      ]).then(() => {
         this.setState({
            componentLoader: false
         });
      });
   }

   shouldComponentUpdate = (nextProps, nextState) => {
      console.log('shouldComponentUpdate', nextProps, this.state)
      if (_.isEqual(nextProps.item, this.state.item)) {
         return false
      }
      return true
   }

   render() {
      console.log('Render supplier data intro', this.state)

      return (
         <Descriptions bordered size='small' column={2}>
            <Descriptions.Item label="Fornitore" span={2}>{this.state.item.company}</Descriptions.Item>
            <Descriptions.Item label="Partiva IVA">{this.state.item.vat}</Descriptions.Item>
            <Descriptions.Item label="Codice Fiscale">{this.state.item.fiscal_code}</Descriptions.Item>
            <Descriptions.Item label="Email">{this.state.item.email}</Descriptions.Item>
            <Descriptions.Item label="PEC">{this.state.item.pec}</Descriptions.Item>
         </Descriptions>
      )
   }
}

function mapStateToProps(state) {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
}

export default connect(mapStateToProps)(SupplierDataIntro)