import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Table, Select, Button, Space, notification, Popconfirm, Typography } from 'antd';
import { InfoCircleOutlined, PlusOutlined, LeftCircleOutlined } from '@ant-design/icons';
// import Highlighter from 'react-highlight-words';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import languageManager from '../../../services/languageManager';

import * as storeActions from './store/actions/taxes';

import Loader from '../../common/PageElements/Loader';
import PageHeader from '../../common/PageElements/PageHeader'

class TaxRatesList extends Component {
   state = {
      loader: true,
      itemList: [],
      searchText: '',
      searchedColumn: '',
      componentLanguage: this.props.authState.defaultLanguage
   };

   componentDidMount() {
      console.log('Component Did Mount - Tax rates List')
      Promise.all([
         this.getList(this.state.current_page, this.state.page_size, this.state.filtersValues),
         // this.getComplements()
      ]).then(() => {
         this.setState({
            pageLoader: false
         });
      }).catch(error => {
         console.error('Mount error: ', error.message)
      });
      // if (!_.isNil(this.props.authState.userData.activeMarketplaces)) {
      //    this.prepareImportDropdown()
      // }
   }

   getList = (page, pageSize, filters) => {
      // asyncronous calls
      this.setState({ tableLoader: true })
      // return storeActions.getListExtended(page, pageSize, filters)
      return storeActions.getList()
         .then(response => {
            console.log(response);
            try {
               this.setState({
                  itemList: response.data,
                  fullListIds: response.full_list_ids,
                  current_page: page,
                  page_size: pageSize,
                  total_result: response.data.total,
                  filters: response.list_filters,
                  appliedFilters: response.applied_filters,
                  tableLoader: false
               });
            } catch (e) {
               this.setState({ tableLoader: false })
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
            }
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
         });
   }

   editItemHandler = (itemId) => {
      this.props.history.push('/taxes/taxrates/edit/' + itemId);
   }

   deleteItemHandler = (itemId) => {
      this.setState({ loader: true });
      storeActions.deleteItem(itemId, true)
         .then(response => {
            console.log(response);
            try {
               // let list = [...this.state.itemList];
               // list = list.filter(item => item.id !== itemId);

               this.setState({ itemList: response.data, loader: false });
               notification.success({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.deleteOk'),
               })
            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
            }

         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   confirmHandler = (onConfirm, itemId) => {
      switch (onConfirm) {
         case 'deleteItem':
            if (itemId) {
               this.deleteItemHandler(itemId);
            }
            break;
         default:
            break;
      }
   }

   // createItemHandler = () => {
   //    this.props.history.push('/taxes/taxrates/edit/');
   // }

   toggleActiveHandler = (itemId) => {
      this.setState({ loader: true });
      storeActions.toggleActive(itemId)
         .then(response => {
            this.setState({ loader: false });
            console.log(response);
            try {
               // this.setState({ itemData: response.data });
               console.log(this.state);
            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
            }

         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
         });
   }

   // closeActionHandler = () => {
   //    alert("ciao");
   // }

   render() {
      if (this.state.pageLoader) return <Loader />;
      const { Title } = Typography
      const { Option } = Select;
      const table_columns = [
         { title: 'Nome', width: 100, dataIndex: 't_name', key: 'name', fixed: 'left' },
         { title: 'Percentuale', width: 100, dataIndex: 'percentage', key: 'percentage', fixed: 'left' },
         {
            title: 'Attiva', width: 100, dataIndex: 'active', key: 'percentage', fixed: 'center',
            render: (text, record) => (
               <span>
                  <Button className="link" onClick={() => this.toggleActiveHandler(record.id)}>{record.active ? <CheckOutlined twoToneColor="green" /> : <CloseOutlined twoToneColor="#red" />}</Button>
               </span>),
         },
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            width: 100,
            render: (text, record) => (
               <Space>
                  <Button className="link" onClick={() => this.editItemHandler(record.id)} title={text}><i className="far fa-edit"></i></Button>
                  <Popconfirm
                     placement="topRight"
                     title="Cancelli?"
                     onConfirm={() => this.confirmHandler('deleteItem', record.id)}
                     okText="Yes"
                     cancelText="No"
                  >
                     <Button><i className="far fa-trash-alt"></i></Button>
                  </Popconfirm>
               </Space>),
         },
      ];

      const tableLayout = {
         bordered: true
      }

      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     <Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.tax_rates.title')}</Title>
                  </div>
                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                        <Space>
                           <Button
                              type='primary'
                              icon={<PlusOutlined />}
                              // onClick={this.showCreateSupplierDrawer}
                              onClick={() => this.editItemHandler()}
                           >
                              {languageManager.getMessage(this.props.commonState, 'component.tax_rates.create_new')}
                           </Button>
                        </Space>
                     </div>
                  </div>
               </div>
            </div>
            <div className="card-body">
               <Table {...tableLayout} columns={table_columns} dataSource={this.state.itemList} />
            </div>
            <div className="card-footer">
               <Button
                  size='large'
                  onClick={() => { this.props.history.goBack() }}
               >
                  <i className="mdi mdi-arrow-left-drop-circle-outline mr-1"></i>
                  {languageManager.getMessage(this.props.commonState, 'common.back')}
               </Button>
            </div>
         </div>
      );

      const helpButton = {
         title: "Aiuto"
      }
      const buttons = [];
      buttons.push(helpButton);

      // const editForm = (
      //    <div className="card">
      //       <div className="card-header">Tasse</div>
      //       <div className="card-body">
      //          <TaxRatesForm action={this.state.action} itemData={this.state.itemData} closeAction={() => this.closeActionHandler()} />
      //       </div>
      //    </div>
      // );

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(TaxRatesList);