import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Moment from 'moment'

import { Modal, Form, Input, InputNumber, Button, Space, Divider, Cascader, DatePicker, notification, Table, Typography } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons';

import languageManager from '../../../../services/languageManager';
import * as storeActions from '../store/actions/orders';
import * as productsActions from '../../../catalogue/products/store/actions/products';

class ModalAddOrderProduct extends Component {
    state = {
        ...this.props,
        componentLoader: true,
        formLoader: false,
        formValues: {},
        actualProduct: null,
        actualProductId: null,
        componentLanguage: this.props.authState.defaultLanguage
    }
    formRef = React.createRef();

    componentDidMount() {
        // console.log('Component did mount')
        Promise.all([
            //  this.getSuppliersList()
        ]).then(() => {
            this.setState({
                componentLoader: false
            });
        });
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        // console.log('Component did update', nextProps, this.state)
        if (_.isEqual(nextProps.visible, this.props.visible) && _.isEqual(nextState, this.state)) {
            return false
        }
        return true
    }

    componentDidUpdate = (prevProps, prevState) => {
        // console.log('Component did update', prevState, this.props)
        if (!_.isEqual(prevProps.visible, this.props.visible)) {
            if (!_.isNil(this.props.item)) {
                const newFormValues = { ...this.props.item }
                Promise.all([
                    this.handleProductChange([this.props.item.product_id])
                ]).then(() => {
                    this.setFormValues(newFormValues)
                    this.setState({ lineId: this.props.item.id })
                })
            } else {
                this.setState({ lineId: null, actualProduct: { stocks: [] }, actualProductId: null, })
            }
            // this.setState({ ...this.props })
            // console.log('Component did update - update state', prevState, this.props)
        }
    }

    setFormValues = (item) => {
        console.log('Set form values', item)
        // console.log(item)
        // console.log(this.formRef)
        if (this.formRef.current) {
            // this.formRef.current.setFieldsValue(item)
            this.formRef.current.setFieldsValue({
                // line_marketplace_id: item.line_marketplace_id,
                lineId: item.id,
                line_index: item.line_index,
                productId: item.product_id,
                sku: item.product_sku,
                productName: item.product_name,
                supplierCode: item.supplier_sku,
                // batch: item.batch,
                // expirationDate: item.expiration_date ? Moment(item.expiration_date) : null,
                quantity: item.quantity,
                taxRatePercentage: item.tax_rate,
                buyPrice: item.unit_price,
                totalPrice: item.total_price,
            })
        }
    }
    addProductFilter = (inputValue, path) => {
        return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    }

    handleProductChange = (value) => {
        console.log('handleProductChange', value)
        this.setState({ productLoader: true })
        if (_.isUndefined(value) || _.isEmpty(value)) {
            this.setState({
                actualProduct: null,
                actualProductId: null,
                productLoader: false
            })
        } else {
            const selectedId = value.pop()
            return productsActions.getItem(selectedId)
                .then(response => {
                    console.log(response);
                    const productData = response.data
                    // let volume = 0.00
                    // if(!_.isNil(productData.length) && !_.isNil(productData.width) && !_.isNil(productData.height)) {
                    //     volume = (parseFloat(productData.length) * parseFloat(productData.width) * parseFloat(productData.height)).toFixed(6)
                    // }
                    this.formRef.current.setFieldsValue({
                        product_id: productData.id,
                        productId: productData.id,
                        sku: productData.sku,
                        productName: productData.name,
                        supplierCode: productData.supplier_sku,
                        quantity_available: productData.quantity + ' (ultimo aggiornamento: ' + (_.isNil(productData.quantity_updated_at_human) ? 'MAI' : productData.quantity_updated_at_human) + ')',
                        buyPrice: productData.price_buy,
                        taxRatePercentage: _.isNil(productData.tax_rate) ? null : productData.tax_rate.percentage,
                    })
                    this.setState({
                        actualProduct: productData,
                        actualProductId: productData.id,
                        productLoader: false
                    })
                }).catch((e) => {
                    notification.error({
                        message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                        description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                    })
                    if (this.props.commonState.debug) console.log(e)
                    this.setState({ productLoader: false })
                })
        }
    }

    handleTotalChange = () => {
        const quantity = this.formRef.current.getFieldValue('quantity')
        const buy_price = this.formRef.current.getFieldValue('buyPrice')
        if (!_.isNil(quantity) && !_.isNil(buy_price)) {
            let total_price = parseFloat(quantity) * parseFloat(buy_price)
            this.formRef.current.setFieldsValue({ totalPrice: total_price })
        }
    }

    onFinish = values => {
        console.log('Add product', values)
        const orderLine = {
            id: values.lineId,
            line_index: values.line_index,
            product_id: values.productId,
            product_sku: values.sku,
            product_name: values.productName,
            supplier_sku: values.supplierCode,
            // batch: values.batch,
            // batch_expiration: values.expirationDate,
            quantity: parseFloat(values.quantity),
            unit_price: parseFloat(values.buyPrice),
            unit_tax: parseFloat(values.buyPrice) / 100 * parseFloat(values.taxRatePercentage),
            total_price: parseFloat(values.totalPrice),
            total_tax: parseFloat(values.totalPrice) / 100 * parseFloat(values.taxRatePercentage),
            tax_rate: parseFloat(values.taxRatePercentage),
        }
        this.formRef.current.resetFields()
        this.state.onSubmit(orderLine)
        this.state.onClose()
        notification.success({
            message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
            description: languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
        })
    }

    onClose = () => {
        this.formRef.current.resetFields()
        this.state.onClose()
    }

    render() {
        console.log('Render modal add product to purchase order', this.state)

        const mainLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 18 },
            layout: "horizontal",
        };

        return (
            <Modal
                visible={this.props.visible}
                title="Aggiungi prodotto"
                width={800}
                onClose={this.onClose}
                onCancel={this.onClose}
                footer={[
                    <Button key="back" onClick={this.onClose} disabled={this.state.productLoader}>Annulla</Button>,
                    <Button key='submitForm' form="formAddProduct" type="primary" htmlType="submit" loading={this.state.productLoader} disabled={!this.state.actualProductId}>Salva</Button>
                ]}
            >
                <Form
                    id="formAddProduct"
                    ref={this.formRef}
                    {...mainLayout}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                >
                    {
                        this.state.lineId ? null : ([
                            <Form.Item
                                label='Cerca prodotto'
                                name='product_id'
                                key='product_id'
                                rules={[{ required: true }]}
                            >
                                <Cascader
                                    placeholder="Seleziona un prodotto"
                                    options={this.props.productCascaderList}
                                    showSearch={{ filter: this.addProductFilter }}
                                    onChange={this.handleProductChange}
                                    loading={this.state.productLoader} />
                            </Form.Item>,
                            <Divider key='search_product_divider' />
                        ])
                    }
                    <Form.Item
                        label="Nome prodotto"
                        name="productName"
                    >
                        <Input disabled />
                    </Form.Item>
                    <Form.Item
                        label="SKU"
                        name="sku"
                    >
                        <Input disabled />
                    </Form.Item>
                    <Form.Item
                        label="Codice fornitore"
                        name="supplierCode"
                    >
                        <Input disabled />
                    </Form.Item>
                    {/* <Form.Item
                        label="Lotto"
                        name="batch"
                    >
                        <Input disabled={!this.state.actualProductId} />
                    </Form.Item>
                    <Form.Item
                        label="Scadenza"
                        name="expirationDate"
                    >
                        <DatePicker
                            placeholder="Data di scadenza"
                            format="DD/MM/YYYY"
                            allowClear={true}
                            disabled={!this.state.actualProductId}
                        />
                    </Form.Item> */}
                    <Form.Item
                        label="Quantità in magazzino"
                        name="quantity_available"
                    >
                        <Input disabled />
                    </Form.Item>
                    <Form.Item
                        label="Quantità"
                        name="quantity"
                    >
                        <InputNumber step={1} min={1} onChange={this.handleTotalChange} disabled={!this.state.actualProductId} />
                    </Form.Item>
                    <Form.Item
                        label="Prezzo di acquisto"
                        name="buyPrice"
                    >
                        <InputNumber decimalSeparator=',' precision={2} addonAfter='€' onChange={this.handleTotalChange} disabled={!this.state.actualProductId}  />
                    </Form.Item>
                    <Form.Item
                        label="IVA"
                        name="taxRatePercentage"
                    >
                        <InputNumber decimalSeparator=',' precision={2} addonAfter='%' disabled />
                    </Form.Item>
                    <Form.Item
                        label="Prezzo totale"
                        name="totalPrice"
                    >
                        <InputNumber decimalSeparator=',' precision={2} addonAfter='€' disabled />
                    </Form.Item>
                    <Form.Item name="productId" noStyle><Input type="hidden" /></Form.Item>
                    <Form.Item name="lineId" noStyle><Input type="hidden" /></Form.Item>
                    <Form.Item name="line_index" noStyle><Input type="hidden" /></Form.Item>
                    <Form.Item label="language" name="language" initialValue={this.state.componentLanguage} noStyle><Input type="hidden" /></Form.Item>
                </Form>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    return {
        authState: state.authenticationState,
        commonState: state.commonState
    };
}

export default connect(mapStateToProps)(ModalAddOrderProduct)