import React, { Component } from 'react'
import { connect } from 'react-redux'

import languageManager from '../../services/languageManager'
import Loader from '../common/PageElements/Loader'

import { notification, Row, Col } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

class Dashboard extends Component {
   state = {
      pageInit: true,
      pageLoader: true,
   }

   componentDidMount() {
      Promise.all([
         // this.getOrdersChartData()
         // this.getDashboardData()
      ]).then(() => {
         this.setState({
            pageLoader: false
         });
      });
   }



   render() {
      const pageContent = (
         this.state.pageLoader ? <Loader /> : (
            <Row>
               <Col span={24}>
                  {/* <OrdersPerMonth /> */}

                  {/* <OrdersStats vendorCodeType={2} vendorCodeGroup={1} /> */}
                  {/* <ProductsStats /> */}
               </Col>
            </Row>
         )
      );

      return (
         pageContent
      )
   }
}

export default Dashboard;