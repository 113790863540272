import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Moment from 'moment'

import { Drawer, Form, Input, InputNumber, Button, Space, DatePicker, Select, notification } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons';

import languageManager from '../../../../services/languageManager';
import * as storeActions from '../store/actions/extra_charges';
import ExtraChargeForm from './ExtraChargeForm'
import Loader from '../../../common/PageElements/Loader'

class CreateExtraChargeDrawer extends Component {
   state = {
      ...this.props,
      componentLoader: true,
      formValues: {},
      componentLanguage: this.props.authState.defaultLanguage
   }
   // formRef = React.createRef();

   componentDidMount() {
      // console.log('Component did mount')
      Promise.all([
         // this.getExtraChargesList()
      ]).then(() => {
         this.setState({
            componentLoader: false
         });
      });
   }

   shouldComponentUpdate = (nextProps, nextState) => {
      // console.log('Component did update', nextProps, this.state)
      if (_.isEqual(nextProps.visible, this.state.visible)) {
         return false
      }
      return true
   }

   // componentDidUpdate = (prevProps, prevState) => {
   //    if (!_.isEqual(prevState.visible, this.props.visible)) {
   //       if (!_.isNil(this.props.item)) {
   //          var newFormValues = { ...this.props.item }
   //          this.setFormValues(newFormValues)
   //       }
   //    }
   // }
   // setFormValues = (item) => {
   //    if (this.formRef.current) {
   //       this.formRef.current.setFieldsValue(item)
   //    }
   // }

   onFinish = values => {
      this.setState({ saveLoader: true });
      values.return_list = true;
      storeActions.createOrUpdate(values)
         .then(response => {
            this.setState({ saveLoader: false });
            try {
               this.state.onSubmit()
               this.state.onClose()
               notification.success({
                  message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
                  description: languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
               })
            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
            }

         }).catch((e) => {
            this.setState({ saveLoader: false });
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
         });
   };

   onClose = () => {
      this.state.onClose()
   }

   render() {
      const formLayout = {
         layout: 'horizontal',
         labelCol: { span: 7 },
         wrapperCol: { span: 15 },
      };

      return (
         <Drawer
         title={(!_.isNil(this.props.item) && !_.isNil(this.props.item.id) ? 'Modifica' : 'Aggiungi') + ' supplemento'}
            width={800}
            onClose={this.onClose}
            visible={this.props.visible}
            drawerStyle={{ paddingTop: 70 }}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
               <div className='float-right'>
                  <Space>
                     <Button onClick={this.onClose} disabled={this.state.saveLoader}>Chiudi</Button>
                     <Button form="ExtraChargeForm" type="primary" htmlType="submit" loading={this.state.saveLoader}>Salva</Button>
                  </Space>
               </div>
            }
         >
         {
            this.state.componentLoader ? <Loader /> : (
               <ExtraChargeForm
                  layout={formLayout}
                  submitForm={this.onFinish}
                  values={this.props.item}
               />
            )
         }
         </Drawer>
      )
   }
}

function mapStateToProps(state) {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
}

export default connect(mapStateToProps)(CreateExtraChargeDrawer)