import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import Moment from 'moment';

import { Typography, Table, Select, Button, Tooltip, Space, notification, Popconfirm, Collapse, Col, Row } from 'antd';
import { InfoCircleOutlined, PlusOutlined, EuroOutlined, DoubleLeftOutlined } from '@ant-design/icons';
// import Highlighter from 'react-highlight-words';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

import languageManager from '../../../services/languageManager';
import { startLoading, endLoading } from '../../../shared/utility';

import * as storeActions from './store/actions/suppliers';

import Loader from '../../common/PageElements/Loader';
import Reloader from '../../common/PageElements/Reloader';
import DropdownMenu from '../../common/components/DropdownMenu'
import CreateSupplierDrawer from './components/CreateSupplierDrawer'

import TableFilters from '../../common/components/TableFilters';

class SuppliersList extends Component {
   state = {
      localPath: this.props.history.location.pathname,
      getError: false,
      pageLoader: true,
      tableLoader: false,
      multipleActionLoader: false,
      loadersSuppliers: [],
      parentId: null,
      itemList: [],
      fullListIds: [],
      componentLanguage: this.props.authState.defaultLanguage,
      current_page: this.props.location.state ? this.props.location.state.previousState.current_page : 1,
      page_size: this.props.location.state ? this.props.location.state.previousState.page_size : 50,
      total_result: 0,

      showTableFilters: false,
      filters: this.props.location.state ? this.props.location.state.previousState.filters : [],
      filtersValues: this.props.location.state ? this.props.location.state.previousState.filtersValues : {},
      appliedFilters: this.props.location.state ? this.props.location.state.previousState.appliedFilters : [],

      selectedRowKeys: [],
      selectedAll: false,
      multipleActionList: [
         {
            action: 'export_csv',
            name: 'Esporta selezionati in CSV'
         },
         // {
         //    action: 'delete_suppliers',
         //    name: 'Cancella fornitori',
         //    needconfirmation: 'yes',
         // },
      ],
      importDropdownActionList: [],
   };

   getSuppliersList = (page, pageSize, filters) => {
      // asyncronous calls
      this.setState({ tableLoader: true })
      return storeActions.getListExtended(page, pageSize, filters)
         .then(response => {
            console.log(response);
            try {
               this.setState({
                  itemList: response.data.data,
                  fullListIds: response.full_list_ids,
                  current_page: page,
                  page_size: pageSize,
                  total_result: response.data.total,
                  filters: response.list_filters,
                  appliedFilters: response.applied_filters,
                  tableLoader: false
               });
            } catch (e) {
               this.setState({ tableLoader: false })
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                  duration: 0,
               })
            }

         }).catch((e) => {
            this.setState({ getError: true });
            if (this.props.commonState.debug) console.log(e);
         });
   }

   prepareImportDropdown = () => {
      let newImport = {}
      let newImportDropdownActionList = []
      // console.log('prepareImportDropdown', this.props.authState.userData)
      _.forEach(this.props.authState.userData.activeMarketplaces, (marketplace) => {
         switch (marketplace.marketplace_base.alias) {
            case 'privalia':
               break
            case 'woocommerce':
               newImport = {
                  action: 'import_from_marketplace',
                  name: 'Importa dati da ' + marketplace.name,
                  params: {
                     marketplace_id: marketplace.id
                  },
                  needconfirmation: 'yes',
               }
               newImportDropdownActionList.push(newImport)
               break
         }
      })
      this.setState({
         importDropdownActionList: newImportDropdownActionList,
      });
   }

   componentDidMount() {
      console.log('Component Did Mount - Suppliers List')
      Promise.all([
         this.getSuppliersList(this.state.current_page, this.state.page_size, this.state.filtersValues),
         // this.getComplements()
      ]).then(() => {
         this.setState({
            pageLoader: false
         });
      }).catch(error => {
         console.error('Mount error: ', error.message)
      });
      // if (!_.isNil(this.props.authState.userData.activeMarketplaces)) {
      //    this.prepareImportDropdown()
      // }
   }
   // componentDidUpdate(prevProps) {
   //    if (this.props.authState.userData.activeMarketplaces !== prevProps.authState.userData.activeMarketplaces) {
   //       this.prepareImportDropdown()
   //    }
   // }

   editItemHandler = (itemId) => {
      this.props.history.push({
         pathname: itemId ? '/suppliers/edit/' + itemId : '/suppliers/edit/',
         state: { previousState: this.state }
      });
   }

   configItemHandler = (itemId) => {
      this.props.history.push({
         pathname: '/suppliers/config/' + itemId,
         state: { previousState: this.state }
      });
   }

   deleteMultipleItemsHandler = (itemIds) => {
      // this.setState({ loader: true });
      // storeActions.deleteMultipleItems(productIds, true)
      //    .then(response => {
      //       console.log(response);
      //       try {
      //          // let list = [...this.state.itemList];
      //          // list = list.filter(item => item.id !== itemId);

      //          this.getProductsList(this.state.current_page, this.state.page_size, this.state.filtersValues);
      //          notification.open({
      //             message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
      //             description: languageManager.getMessage(this.props.commonState, 'notification.confirm.deleteOk'),
      //             icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      //          })
      //       } catch (e) {
      //          notification.open({
      //             message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
      //             description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
      //             icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      //          })
      //       }

      //    }).catch((e) => {
      //       notification.open({
      //          message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
      //          description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
      //          icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      //       })
      //       if (this.props.commonState.debug) console.log(e);
      //    });
   }

   deleteItemHandler = (itemId) => {
      this.setState({ loadersSuppliers: startLoading(this.state.loadersSuppliers, itemId) });
      storeActions.deleteItem(itemId, true)
         .then(response => {
            console.log(response);
            try {
               Promise.all([
                  this.getSuppliersList(this.state.current_page, this.state.page_size, this.state.filtersValues)
               ]).then(() => {
                  this.setState({ loadersSuppliers: endLoading(this.state.loadersSuppliers, itemId) });
               });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.deleteOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
            }

         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   importFromMarketplaceHandler = (marketplaceId) => {
      this.setState({ importLoader: true });
      storeActions.importFromMarketplace(marketplaceId)
         .then(response => {
            console.log(response);
            try {
               Promise.all([
                  this.getSuppliersList(this.state.current_page, this.state.page_size, this.state.filtersValues)
               ]).then(() => {
                  this.setState({ importLoader: false });
               });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.importOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               this.setState({ importLoader: false });
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
            }

         }).catch((e) => {
            this.setState({ importLoader: false });
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   confirmHandler = (onConfirm, itemIds) => {
      switch (onConfirm) {
         case 'deleteItem':
            if (itemIds) {
               this.deleteItemHandler(itemIds);
            }
            break;
         default:
            break;
      }
   }

   setDefaultLanguageHandler = (value) => {
      this.setState({ componentLanguage: value })
   }

   suppliersExportHandler = (marketplace, supplierIds, params) => {
      let filename
      switch (marketplace) {
         case 'nzt':
            this.setState({ multipleActionLoader: true });
            filename = Moment().format('YYYY.MM.DD-HH.mm.ss') + '_SUPPLIERS.csv';
            storeActions.exportCSV(filename, supplierIds)
               .then(response => {
                  this.setState({ multipleActionLoader: false });
                  console.log(response);
                  try {
                     this.setState({ loader: false });
                  } catch (e) {
                     notification.open({
                        message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                        description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                        icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                     })
                  }

               }).catch((e) => {
                  this.setState({ multipleActionLoader: false });
                  notification.open({
                     message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                     description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                     icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                  })
                  if (this.props.commonState.debug) console.log(e);
               });
            break;
         default:
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.no_marketplace_selected'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            break;
      }
   }

   onSelectChange = (selectedRowKeys) => {
      this.setState({
         selectedRowKeys: selectedRowKeys
      });
      console.log(selectedRowKeys);
   };

   submitFilters = (values) => {
      this.setState({ filtersValues: values })
      this.getSuppliersList(this.state.current_page, this.state.page_size, values)
   }
   resetFilters = () => {
      this.getSuppliersList(this.state.current_page, this.state.page_size, []);
   }

   selectAllHandler = () => {
      if (this.state.selectedAll) {
         this.setState({
            selectedAll: false,
            selectedRowKeys: [],
         });
      }
      else {
         this.setState({
            selectedAll: true,
            selectedRowKeys: this.state.fullListIds,
         });
      }
   }
   multipleActionHandler = (value) => {
      console.log(value)
      const props = value.item.props
      switch (props.action) {
         case 'import_from_marketplace':
            this.importFromMarketplaceHandler(props.params.marketplace_id);
            break;
         case 'export_csv':
            this.suppliersExportHandler('nzt', this.state.selectedRowKeys);
            break;
         case 'delete_suppliers':
            this.deleteMultipleItemsHandler(this.state.selectedRowKeys);
            break;
         default:
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            break;

      }
   }

   handleTableChange = (pagination, filters, sorter, extra) => {
      console.log(pagination, filters, sorter, extra);
      this.getSuppliersList(pagination.current, pagination.pageSize, this.state.filtersValues);
   };


   editSupplierHandler = item => {
      this.setState({ createSupplierDrawerItem: item, showCreateSupplierDrawer: true })
   }
   showCreateSupplierDrawer = () => {
      this.setState({ createSupplierDrawerItem: null, showCreateSupplierDrawer: true })
   }
   hideCreateSupplierDrawer = () => {
      this.setState({ createSupplierDrawerItem: null, showCreateSupplierDrawer: false })
   }
   handleCreateSupplier = (Suppliers_list) => {
      console.log('Quantità in magazzino creata correttamente')
      var newFormValues = { ...this.state.formValues }
      newFormValues.Suppliers = Suppliers_list
      this.setState({ formValues: newFormValues, createSupplierDrawerItem: null, showCreateSupplierDrawer: false })
   }

   render() {
      console.log('Suppliers list RENDER');
      if (this.state.getError) return <Reloader />;
      if (this.state.pageLoader) return <Loader />;
      const { Title } = Typography
      const { Option } = Select;
      const { Panel } = Collapse;

      const table_columns = [
         {
            title: 'Codice interno',
            key: 'internal_code',
            fixed: 'left',
            width: 150,
            render: (record) => (
               record.internal_code
            ),
            sorter: (a, b) => a.internal_code.localeCompare(b.internal_code),
         },
         {
            title: 'Codice Quadrivium',
            key: 'quadrivium_code',
            fixed: 'left',
            width: 150,
            render: (record) => (
               record.quadrivium_code
            ),
            sorter: (a, b) => a.quadrivium_code.localeCompare(b.quadrivium_code),
         },
         {
            title: 'Azienda',
            key: 'company_name',
            fixed: 'left',
            render: (record) => (
               record.company
            ),
            sorter: (a, b) => a.company.localeCompare(b.company),
         },
         {
            title: 'Partita IVA',
            key: 'vat',
            width: 150,
            render: (record) => (
               record.vat
            ),
            sorter: (a, b) => a.vat.localeCompare(b.vat),
         },
         {
            title: 'Codice Fiscale',
            key: 'fiscal_code',
            width: 150,
            render: (record) => (
               record.fiscal_code
            ),
            sorter: (a, b) => a.fiscal_code.localeCompare(b.fiscal_code),
         },
         {
            title: 'SDI',
            key: 'sdi',
            width: 100,
            render: (record) => (
               record.sdi
            ),
         },
         {
            title: 'Email',
            key: 'email',
            width: 200,
            render: (record) => (
               record.email
            ),
            sorter: (a, b) => a.email.localeCompare(b.email),
         },
         {
            title: 'PEC',
            key: 'pec',
            width: 200,
            render: (record) => (
               record.pec
            ),
            sorter: (a, b) => a.pec.localeCompare(b.pec),
         },
         {
            title: 'Telefono',
            key: 'phone',
            width: 200,
            render: (record) => (
               record.phone
            ),
            sorter: (a, b) => {
               a = a.phone || ''
               b = b.phone || ''
               return a.localeCompare(b)
            },
         },
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            width: 100,
            render: (text, record) => (
               <Space>
                  <Button type="primary" size='small'
                     disabled={this.state.loadersSuppliers[record.id]}
                     icon={<EditOutlined />}
                     onClick={() => this.editItemHandler(record.id)}
                  />
                  <Button type="primary" size='small'
                     title='Configura supplementi'
                     disabled={this.state.loadersSuppliers[record.id]}
                     icon={<EuroOutlined />}
                     onClick={() => this.configItemHandler(record.id)}
                  />
                  {/* <Dropdown.Button type="primary" onClick={() => this.editItemHandler(record.id)} overlay={actionsMenu(record.id)}><EditOutlined /></Dropdown.Button> */}
                  <Popconfirm
                     placement="topRight"
                     title={languageManager.getMessage(this.props.commonState, 'common.askDeleteConfirm')}
                     onConfirm={() => this.confirmHandler('deleteItem', record.id)}
                     okText={languageManager.getMessage(this.props.commonState, 'common.yes')}
                     cancelText={languageManager.getMessage(this.props.commonState, 'common.no')}
                  >
                     <Button type="danger" size='small'
                        loading={this.state.loadersSuppliers[record.id]}
                        icon={<DeleteOutlined />}
                     />
                  </Popconfirm>
               </Space>)
         },
      ];
      const tableLayout = {
         bordered: true,
         size: "small",
         loading: this.state.tableLoader,
         pagination: {
            pageSize: this.state.page_size,
            total: this.state.total_result,
            showSizeChanger: true,
            defaultPageSize: 50,
            size: "small",
            showTotal: total => total + (total !== 1 ? ' clienti' : ' cliente')
         },
         onChange: this.handleTableChange
      }
      const { selectedRowKeys } = this.state;
      const rowSelection = {
         selectedRowKeys,
         onChange: this.onSelectChange,
         preserveSelectedRowKeys: true
      };

      const pageFilters = (
         <Collapse defaultActiveKey={['filters']}>
            <Panel header="Filtri" key="filters">
               <TableFilters submitFilters={this.submitFilters} resetFilters={this.resetFilters} filters={this.state.filters} selectValues={this.state.filtersValues} />
            </Panel>
         </Collapse>
      );

      const hasSelected = selectedRowKeys.length > 0;
      const singleSelected = selectedRowKeys.length > 1;
      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     <Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.suppliers.title')}</Title>
                  </div>
                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                        <Space>
                           <Button
                              type='primary'
                              icon={<PlusOutlined />}
                              // onClick={this.showCreateSupplierDrawer}
                              onClick={() => this.editItemHandler()}
                           >
                              {languageManager.getMessage(this.props.commonState, 'component.suppliers.create_new')}
                           </Button>
                        </Space>
                     </div>
                  </div>
               </div>
            </div>
            <div className="card-body">
               <Row>
                  <Col span={24}>
                     {pageFilters}
                  </Col>
               </Row>
               <Row>
                  <Col span={24}>
                     <div className="align-items-center row" style={{ marginTop: 15, marginBottom: 5 }}>
                        <div className="col-sm-12">
                           <div className="d-none d-md-block">
                              <Space>
                                 <Button
                                    onClick={() => this.selectAllHandler()}
                                    disabled={_.isEmpty(this.state.fullListIds)}
                                 >{this.state.selectedAll ? 'Deseleziona' : 'Seleziona tutto'}
                                 </Button>
                                 <span>
                                    {hasSelected ? singleSelected ? `${selectedRowKeys.length} elementi selezionati` : `${selectedRowKeys.length} elemento selezionato` : ''}
                                 </span>
                                 <DropdownMenu
                                    type='primary'
                                    title='Azioni multiple'
                                    action={(event) => this.multipleActionHandler(event)}
                                    values={this.state.multipleActionList}
                                    disabled={!hasSelected}
                                    loading={this.state.multipleActionLoader}
                                 />
                              </Space>
                           </div>
                        </div>
                     </div>
                     <Table
                        key='dataTable'
                        {...tableLayout}
                        rowSelection={rowSelection}
                        columns={table_columns}
                        dataSource={this.state.itemList}
                        rowKey={record => (record.id)}
                     />
                     <CreateSupplierDrawer
                        key='createSupplierDrawer'
                        visible={this.state.showCreateSupplierDrawer}
                        itemId={this.state.itemId}
                        item={this.state.createSupplierDrawerItem}
                        onClose={this.hideCreateSupplierDrawer}
                        onSubmit={(suppliers_list) => this.handleCreateSupplier(suppliers_list)} />
                  </Col>
               </Row>
            </div>
            <div className="card-footer">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     <Space size='small'>
                        <Link to={{ pathname: "/" }} >
                           <Button icon={<DoubleLeftOutlined />}>
                              {languageManager.getMessage(this.props.commonState, 'common.back')}
                           </Button>
                        </Link>
                     </Space>
                  </div>

                  <div className="col-sm-6">
                  </div>
               </div>
            </div>
         </div>
      );

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   };
};

export default connect(mapStateToProps)(SuppliersList);