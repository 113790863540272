import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import languageManager from '../../../../services/languageManager'

import { Form, Input, Radio, Select, Switch, Divider } from 'antd'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'

const ExtraChargeForm = (props) => {
    const [layout, setLayout] = useState(props.layout)
    const [values, setValues] = useState(props.values)

    const [form] = Form.useForm()
    const { Option } = Select

    useEffect(() => {
        console.log(props)
        setValues(props.values)
        if (_.isEmpty(props.values)) {
            form.resetFields()
        } else {
            form.setFieldsValue(props.values)
        }
    }, [props.values])

    const submitForm = (values) => {
        console.log(values)
        console.log('Submit form')
        props.submitForm(values)
    }

    return (
        <Form
            form={form}
            {...layout}
            id="ExtraChargeForm"
            onFinish={submitForm}
        >
            <Form.Item
                label="Nome"
                name="name"
                rules={[{ required: true, message: 'Inserisci un nome identificativo per il supplemento' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Descrizione"
                name="description"
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Applica a tipo di ordine"
                name="apply_on_charging"
            >
                <Radio.Group defaultValue={0}>
                    <Radio.Button value={1}>Entrata</Radio.Button>
                    <Radio.Button value={0}>Uscita</Radio.Button>
                </Radio.Group>
            </Form.Item>
            <Form.Item
                label="Applica alla quantità"
                name="auto_apply_on_quantity"
                valuePropName='checked'
            >
                <Switch />
            </Form.Item>
            <Form.Item name="id" noStyle><Input type="hidden" /></Form.Item>
        </Form>
    )
}

const mapStateToProps = state => {
    return {
        commonState: state.commonState,
    }
}

export default connect(mapStateToProps)(ExtraChargeForm)