import API_BASE from './apiBase';
import API_CONFIG from './apiConfig';

import Axios from 'axios';

class SuppliersApi {

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getList(parentId) {

      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.suppliers.getList, {
            parent_id: parentId
         })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getListExtended(page, pageSize, filters) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.suppliers.getListExtended, {
            page: page,
            page_size: pageSize,
            filters_values: JSON.stringify(filters)
         })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getItem(itemId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.suppliers.getItem, {
            id: itemId
         })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static getComplements() {
      return new Promise((resolve, reject) => {
         Axios.all([
            Axios.post(API_BASE.url + API_CONFIG.appUrls.taxRates.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.extraCharges.getList),
         ])
            .then(Axios.spread((taxRatesList, extraChargesList) => {
               const response = {
                  taxRatesList: taxRatesList,
                  extraChargesList: extraChargesList,
               }
               resolve(response);
            }))
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static createOrUpdate(itemData,) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.suppliers.createOrUpdate, {
            ...itemData
         })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static deleteItem(itemId, return_list) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.suppliers.deleteItem, {
            id: itemId,
            return_list: return_list
         })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static importFromMarketplace(marketplaceId, return_list) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.suppliers.importFromMarketplace, {
            marketplace_id: marketplaceId,
            return_list: return_list
         })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   } 
   static importFromCSV(formData) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.suppliers.importFromCSV, { ...formData })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static exportCSV(filename, supplierIds) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.suppliers.exportCSV, { filename: filename, supplier_ids: supplierIds })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   
   static getExtraCharges(itemId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.suppliers.getExtraCharges, {
            id: itemId
         })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static saveExtraCharges(itemId, values) {
      console.log('TO SAVE', JSON.stringify(values))
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.suppliers.saveExtraCharges, {
            supplier_id: itemId,
            extra_charges: JSON.stringify(values)
         })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }
}

export default SuppliersApi;