import React from 'react';
// import _ from 'lodash';

import { Menu, Dropdown } from 'antd';

const DropdownButton = (props) => {
   const menu = (
      <Menu onClick={props.action}>
         {
            props.values.map((value) => {
               return <Menu.Item key={value.id}>{value.name}</Menu.Item>
            })
         }
      </Menu>
   );

   return (
      <Dropdown.Button
         overlay={menu}
         size={props.size ? props.size : 'large'}
         type={props.type ? props.type : 'primary'}
         disabled={props.disabled}
         buttonsRender={([leftButton, rightButton]) => [
            leftButton,
            React.cloneElement(rightButton, { loading: props.loading }),
         ]}
      >
         {props.title}
      </Dropdown.Button>
   )
}

export default DropdownButton;