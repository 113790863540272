import Axios from 'axios';
import API_BASE from './apiBase';
import API_CONFIG from './apiConfig';

class OrdersApi {

   static createOrUpdate(itemData) {
      console.log(itemData)
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.orders.createOrUpdate, {
            ...itemData
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               console.log(error);
               reject(error);
            });

      });
   }

   static getList() {

      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.orders.getList)
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getListExtended(page, pageSize, filters) {

      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.orders.getListExtended, { page: page, page_size: pageSize, filters_values: JSON.stringify(filters) })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getListFilters() {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.orders.getListFilters, {})
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getOrdersFilterComplements() {
      return new Promise((resolve, reject) => {
         Axios.all([
            Axios.post(API_BASE.url + API_CONFIG.appUrls.orderstatuses.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.marketplaces.getList),
         ])
            .then(Axios.spread((orderStatusesList, marketplacesList) => {
               const response = {
                  orderStatusesList: orderStatusesList,
                  marketplacesList: marketplacesList
               }
               resolve(response);
            }))
            .catch(error => {
               reject(error);
            });
      });
   }

   static getItem(itemId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.orders.getItem, {
            id: itemId
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static getItemByCode(itemCode, codeType) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.orders.getItemByCode, {
            code: itemCode,
            code_type: codeType
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static getComplements() {
      return new Promise((resolve, reject) => {
         Axios.all([
            Axios.post(API_BASE.url + API_CONFIG.appUrls.customers.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.suppliers.getList),
            // Axios.post(API_BASE.url + API_CONFIG.appUrls.shippingMethods.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.products.getListCascader, { allow_select_parent: true }),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.extraCharges.getList),
         ])
            .then(Axios.spread((customerList, supplierList, productCascaderList, extraChargesList) => {
               const response = {
                  customerList: customerList,
                  supplierList: supplierList,
                  // shippingMethodList: shippingMethodList,
                  productCascaderList: productCascaderList,
                  extraChargesList: extraChargesList,
               }
               resolve(response);
            }))
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static updateOrderStatus(orderId, newStatus) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.orders.updateStatus, {
            order_id: orderId,
            new_status: newStatus,
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }
   static cancelOrder(orderId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.orders.cancelOrder, { order_id: orderId })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static sendToLogistics(orderId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.orders.sendToLogistics, { order_id: orderId })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static sendToQuadrivium(orderId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.orders.sendToQuadrivium, { order_id: orderId })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static shipOrder(orderId, params) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.orders.shipOrder, {
            order_id: orderId,
            ...params
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static exportOrders(filename, orderIds) {

      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.orders.exportOrders, { filename: filename, order_ids: orderIds })
            .then(response => {
               // window.open(API_BASE.url + API_CONFIG.appUrls.orders.exportOrders);
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }
   static printDetails(filename, itemId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.orders.printDetails, { filename: filename, item_id: itemId }, { responseType: 'blob' })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
   *
   * @param
   * @returns {Promise<unknown>}
   */
   static importFileCSV(formData) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.orders.importFileCSV, { ...formData })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

}

export default OrdersApi;