import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import Moment from 'moment'

import { OrderStatusEnum, OrderStatusActionEnum } from '../../../shared/enums.ts'

import { Table, Button, Tooltip, Space, notification, Typography, Row, Col, Collapse } from 'antd'
import { InfoCircleOutlined, EyeOutlined, UploadOutlined, DeleteOutlined, EditOutlined, PrinterOutlined, CheckOutlined, PlusOutlined } from '@ant-design/icons'

import languageManager from '../../../services/languageManager'
import { startLoading, endLoading } from '../../../shared/utility'

import * as storeActions from './store/actions/orders'

import Loader from '../../common/PageElements/Loader'
import TableFilters from '../../common/components/TableFilters'

class OrdersList extends Component {
   state = {
      isCustomer: !_.isNil(this.props.authState.userData.account.parent_account_id),
      localPath: this.props.history.location.pathname,
      pageLoader: true,
      tableLoader: false,
      multipleActionLoader: false,
      orderStatusActionLoader: false,
      orderPrintLoaders: [],

      itemList: [],
      fullListIds: [],
      componentLanguage: this.props.authState.defaultLanguage,

      current_page: this.props.location.state ? this.props.location.state.previousState.current_page : 1,
      page_size: this.props.location.state ? this.props.location.state.previousState.page_size : 50,
      total_result: 0,

      selectedRowKeys: [],
      selectedAll: false,
      multipleActionList: [
         {
            action: 'export',
            name: 'Esporta selezionati in CSV'
         },
         // {
         //    action: OrderStatusActionEnum.MULTIPLE_ACCEPT.toString(),
         //    name: 'Accetta ordini',
         //    needconfirmation: 'yes'
         // },
         // {
         //    action: OrderStatusActionEnum.MULTIPLE_PROCESS.toString(),
         //    name: 'Metti in preparazione',
         //    needconfirmation: 'yes'
         // },
         // {
         //    action: OrderStatusActionEnum.MULTIPLE_REFUSE.toString(),
         //    name: 'Rifiuta ordini',
         //    needconfirmation: 'yes'
         // }
      ],
      selectedWaitingAcceptance: true,
      action: "",

      showTableFilters: false,
      filters: this.props.location.state ? this.props.location.state.previousState.filters : [],
      filtersValues: this.props.location.state ? this.props.location.state.previousState.filtersValues : {},
      appliedFilters: this.props.location.state ? this.props.location.state.previousState.appliedFilters : [],

      activeMarketplaces: [],

   };

   getOrdersList = (page, pageSize, filters) => {
      // asyncronous calls
      this.setState({ tableLoader: true })
      return storeActions.getListExtended(page, pageSize, filters)
         .then(response => {
            console.log(response);
            try {
               let updatedFilters = []
               for (let filter of response.list_filters) {
                  if (filter.type === 'date') {
                     if (filter.value) {
                        filter.value = Moment(filter.value, 'YYYY-MM-DD');
                     }
                  }
                  updatedFilters.push(filter);
               }

               this.setState({
                  itemList: response.data.data,
                  fullListIds: response.full_list_ids,
                  current_page: page,
                  page_size: pageSize,
                  total_result: response.data.total,
                  filters: updatedFilters,
                  appliedFilters: response.applied_filters,
                  tableLoader: false
               });
            } catch (e) {
               this.setState({ tableLoader: false })
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
            }

         }).catch((e) => {
            this.setState({ tableLoader: false })
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   componentDidMount() {
      Promise.all([
         this.getOrdersList(this.state.current_page, this.state.page_size, this.state.filtersValues),
      ]).then(() => {
         this.setState({
            pageLoader: false
         });
      });
   }

   viewItemHandler = (itemId) => {
      this.props.history.push({
         pathname: '/orders/summary/' + itemId,
         state: { previousState: this.state }
      });
   }

   // getOrdersExportHandler = (selectedRows) => {
   //    let filename = 'export_orders_data.csv';
   //    storeActions.exportOrders(filename, selectedRows)
   //       .then(response => {
   //          console.log(response);
   //          try {
   //             this.setState({ loader: false });
   //          } catch (e) {
   //             notification.open({
   //                message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
   //                description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
   //                icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
   //             })
   //          }

   //       }).catch((e) => {
   //          notification.open({
   //             message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
   //             description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
   //             icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
   //          })
   //          if (this.props.commonState.debug) console.log(e);
   //       });
   // }

   exportToCSVHandler = () => {
      // console.log(this.state.selectedRowKeys);
      this.setState({ multipleActionLoader: true });
      let now = Moment().format('YYYY.MM.DD-HH.mm.ss');
      let filename = now + '_ORDERS.csv';
      storeActions.exportOrders(filename, this.state.selectedRowKeys)
         .then(response => {
            console.log(response);
            this.setState({ multipleActionLoader: false });
            try {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.exportOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
            }

         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            this.setState({ multipleActionLoader: false });
            if (this.props.commonState.debug) console.log(e);
         });
   }

   printDetailsHandler = (itemId) => {
      this.setState({ orderPrintLoaders: startLoading(this.state.orderPrintLoaders, itemId) });
      let filename = 'USCITA_MERCE_' + itemId + '.pdf';
      storeActions.printDetails(filename, itemId)
         .then(response => {
            this.setState({ orderPrintLoaders: endLoading(this.state.orderPrintLoaders, itemId) });
            notification.success({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.confirm.exportOk'),
            })
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            this.setState({ orderPrintLoaders: endLoading(this.state.orderPrintLoaders, itemId) });
            if (this.props.commonState.debug) console.log(e);
         });
   }

   orderStatusHandler = (orderId, newStatus) => {
      this.setState({ loader: true });
      storeActions.updateOrderStatus(orderId, newStatus)
         .then(response => {
            console.log(response);
            try {
               this.getOrdersList(this.state.current_page, this.state.page_size, this.state.filtersValues);
            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
            }
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   listPaginationChangeHandler = (page, pageSize) => {
      console.log(page, pageSize, this.state.filtersValues);
      this.getOrdersList(page, pageSize, this.state.filtersValues);
   }

   onSelectChange = (selectedRowKeys, selection) => {
      this.setState({
         selectedRowKeys: selectedRowKeys,
         selectedWaitingAcceptance: this.checkSelectionStatus(selection)
      });
      console.log(selection);
   };

   checkSelectionStatus = (selection) => {
      if (this.state.selectedAll) {
         return false;
      }
      selection.map((record) => {
         if (record.status != "WAITING_ACCEPTANCE") {
            return false;
         }
      })
      return true;
   }

   submitFilters = (values) => {
      // console.log("Selected filters", values)
      Object.keys(values).forEach(function (key) {
         if (Moment.isMoment(values[key])) {
            values[key] = Moment(values[key]).format('YYYY-MM-DD');
         }
      });
      this.setState({ filtersValues: values })
      this.getOrdersList(1, this.state.page_size, values);
   }
   resetFilters = () => {
      console.log("Reset filters")
      this.getOrdersList(1, this.state.page_size, []);
   }

   setActionHandle = (value) => {
      this.setState({
         action: value
      })
   }

   selectAllHandler = () => {
      if (this.state.selectedAll) {
         this.setState({
            selectedAll: false,
            selectedRowKeys: [],
         });
      }
      else {
         this.setState({
            selectedAll: true,
            selectedRowKeys: this.state.fullListIds,
         });
      }
   }
   multipleActionHandler = (value) => {
      const props = value.item.props
      switch (props.action) {
         case 'export':
            this.exportToCSVHandler();
            break;
         case OrderStatusActionEnum.MULTIPLE_ACCEPT.toString():
         case OrderStatusActionEnum.MULTIPLE_PROCESS.toString():
         case OrderStatusActionEnum.MULTIPLE_REFUSE.toString():
            this.handleMultipleOrdersAction(props.action);
            break;
         default:
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
            })
            break;

      }
   }

   render() {
      if (this.state.pageLoader) return <Loader />;
      const { Title, Text } = Typography
      const { Panel } = Collapse;

      let table_columns = [];
      table_columns.push(
         // { title: 'ID', width: 100, dataIndex: 'id', key: 'id', fixed: 'left' },
         // {
         //    title: 'ID Marketplace',
         //    width: 150,
         //    dataIndex: 'marketplace_id',
         //    key: 'marketplace_id',
         //    fixed: 'left',
         //    render: (text, record) => (
         //       <a onClick={() => this.viewItemHandler(record.id)} >{text}</a>
         //    ),
         // },
         {
            title: 'Numero ordine',
            dataIndex: 'internal_order_number',
            key: 'internal_order_number',
            fixed: 'left',
            render: (text, record) => (
               <Link to={'/orders/details/' + record.id}>{text}</Link>
            ),
            sorter: (a, b) => a.internal_order_number.localeCompare(b.internal_order_number),
         },
         {
            title: 'Ordine fornitore',
            width: 150,
            dataIndex: 'supplier_order_number',
            key: 'supplier_order_number',
            sorter: (a, b) => {
               a = a.supplier_order_number || ''
               b = b.supplier_order_number || ''
               return a.localeCompare(b)
            },
         },
         {
            title: 'Ordine cliente',
            width: 150,
            dataIndex: 'customer_order_number',
            key: 'customer_order_number',
            sorter: (a, b) => {
               a = a.customer_order_number || ''
               b = b.customer_order_number || ''
               return a.localeCompare(b)
            },
         },
         {
            title: 'Data ordine',
            width: 140,
            key: 'create_order_date',
            render: (record) => (
               Moment(record.create_order_date).format('L LTS')
            ),
            sorter: (a, b) => a.create_order_date.localeCompare(b.create_order_date),
         },
         {
            title: 'Spedizione richiesta',
            width: 140,
            key: 'shipping_request_date',
            render: (record) => (
               record.shipping_request_date ? Moment(record.shipping_request_date).format('L') : null
            ),
            sorter: (a, b) => {
               a = a.shipping_request_date || ''
               b = b.shipping_request_date || ''
               return a.localeCompare(b)
            },
         },
         {
            title: 'Data spedizione',
            width: 140,
            key: 'shipped_order_date',
            render: (record) => (
               record.shipped_order_date ? Moment(record.shipped_order_date).format('L') : null
            ),
            sorter: (a, b) => {
               a = a.shipped_order_date || ''
               b = b.shipped_order_date || ''
               return a.localeCompare(b)
            },
         }
      );
      if (!this.state.isCustomer) {
         table_columns.push(
            {
               title: 'Fornitore', key: 'customer',
               render: (record) => (
                  record.supplier_name
               ),
               sorter: (a, b) => {
                  a = a.supplier_name || ''
                  b = b.supplier_name || ''
                  return a.localeCompare(b)
               },
            }
         )
      };
      table_columns.push(
         {
            title: 'Stato', width: 200, key: 'status',
            render: (record) => (
               record.order_status.name
            ),
            sorter: (a, b) => a.order_status.order_column - (b.order_status.order_column),
         }
      );
      if (this.state.isCustomer) {
         table_columns.push(
            {
               title: 'Inviato alla logistica',
               width: 140,
               key: 'sent_to_logistics',
               render: (record) => (
                  record.sent_to_logistics_human
               ),
               sorter: (a, b) => {
                  a = a.sent_to_logistics || ''
                  b = b.sent_to_logistics || ''
                  return a.localeCompare(b)
               },
            }
         )
      } else {
         table_columns.push(
            {
               title: 'Inviato a Quadrivium',
               width: 140,
               key: 'sent_to_quadrivium',
               render: (record) => (
                  record.sent_to_quadrivium ? Moment(record.sent_to_quadrivium).format('L LTS') : null
               ),
               sorter: (a, b) => {
                  a = a.sent_to_quadrivium || ''
                  b = b.sent_to_quadrivium || ''
                  return a.localeCompare(b)
               },
            }
         )
      }
      table_columns.push(
         {
            title: 'Bancali', width: 70, dataIndex: 'pallets_quantity', key: 'pallets_quantity',
            sorter: (a, b) => {
               a = a.pallets_quantity || 0
               b = b.pallets_quantity || 0
               return a - b
            },
         },
         {
            title: 'Colli', width: 70, dataIndex: 'packages_quantity', key: 'packages_quantity',
            sorter: (a, b) => {
               a = a.packages_quantity || 0
               b = b.packages_quantity || 0
               return a - b
            },
         },
         {
            title: 'Quantità', width: 70, dataIndex: 'total_quantity', key: 'total_quantity',
            sorter: (a, b) => {
               a = a.total_quantity || 0
               b = b.total_quantity || 0
               return a - b
            },
         },
         {
            title: 'Valore prodotti', width: 100, key: 'total_value',
            render: (record) => (
               record.total_value_human ? record.total_value_human + '€' : ''
            ),
         },
         {
            title: 'Supplementi', width: 100, key: 'total_extra_charges',
            render: (record) => (
               record.total_extra_charges_human ? record.total_extra_charges_human + '€' : ''
            ),
         },
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            width: 100,
            render: (text, record) => (
               <Space size='small'>
                  {/* <Link to={'/orders/details/' + record.id}>
                     <Button type="warning" size="small"
                        icon={<EyeOutlined />}
                        title={languageManager.getMessage(this.props.commonState, 'common.view')}
                     />
                  </Link> */}
                  <Link to={'/orders/edit/' + record.id}>
                     <Button type="primary" size="small"
                        icon={<EditOutlined />}
                        title={languageManager.getMessage(this.props.commonState, 'common.edit')}
                        disabled={this.state.isCustomer && !_.isNil(record.sent_to_logistics)}
                     />
                  </Link>
                  <Button size="small"
                     icon={<PrinterOutlined />}
                     title={languageManager.getMessage(this.props.commonState, 'common.print')}
                     loading={this.state.orderPrintLoaders[record.id]}
                     onClick={() => this.printDetailsHandler(record.id)}
                  />
               </Space>
            ),
         },
      );

      const productsColumns = [
         {
            title: 'SKU',
            key: 'product_sku',
            dataIndex: 'product_sku',
            width: 150
         },
         {
            title: 'Prodotto', key: 'product_name',
            render: (record) => (
               record.product_name
            ),
         },
         {
            title: languageManager.getMessage(this.props.commonState, 'component.order_summary.products.table.unit_price'),
            width: 100,
            key: 'unit_price',
            render: (record) => (
               record.unit_price_comma ? record.unit_price_comma + '€' : ''
            ),
         },
         {
            title: languageManager.getMessage(this.props.commonState, 'component.order_summary.products.table.quantity'),
            width: 100,
            dataIndex: 'quantity',
            key: 'quantity',
         },
         {
            title: languageManager.getMessage(this.props.commonState, 'component.order_summary.products.table.total_price'),
            width: 100,
            key: 'total_price',
            render: (record) => (
               record.total_price_comma ? record.total_price_comma + '€' : ''
            ),
         },
      ];

      const tableLayout = {
         bordered: true,
         className: "table-striped-rows",
         size: "small",
         loading: this.state.tableLoader,
         onChange: this.handleTableChange,
         pagination: {
            pageSize: this.state.page_size,
            onChange: this.listPaginationChangeHandler,
            total: this.state.total_result
         }
      }

      const { selectedRowKeys } = this.state;
      const rowSelection = {
         selectedRowKeys,
         onChange: this.onSelectChange,
         preserveSelectedRowKeys: true,
      };

      const pageFilters = (
         <Collapse defaultActiveKey={['filters']}>
            <Panel header="Filtri" key="filters">
               <TableFilters submitFilters={this.submitFilters} resetFilters={this.resetFilters} filters={this.state.filters} selectValues={this.state.filtersValues} />
            </Panel>
         </Collapse>
      );

      const hasSelected = selectedRowKeys.length > 0;
      const singleSelected = selectedRowKeys.length > 1;
      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="align-items-center row">
                  <div className="col-sm-6"><Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.orders.title')}</Title></div>
                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                        <Space>
                           <Link to={'/orders/edit'}>
                              <Button type="primary"
                                 icon={<PlusOutlined />}>Crea ordine manuale</Button>
                           </Link>
                           {/* <Button
                              type='primary'
                              icon={<UploadOutlined />}
                              onClick={() => this.props.history.push('/orders/import_csv/')}
                           >
                              Importa CSV
                           </Button> */}
                        </Space>
                     </div>
                  </div>
               </div></div>
            <div className="card-body">
               <Row>
                  <Col span={24}>{pageFilters}</Col>
               </Row>
               <Row>
                  <Col span={24}>
                     <div className="align-items-center row" style={{ marginTop: 15, marginBottom: 5 }}>
                        <div className="col-sm-6">
                           {/* <Space direction='horizontal' size='small'>
                              <Button
                                 onClick={() => this.selectAllHandler()}
                              >{this.state.selectedAll ? 'Deseleziona' : 'Seleziona tutto'}
                              </Button>
                              <span>
                                 {hasSelected ? singleSelected ? `${selectedRowKeys.length} elementi selezionati` : `${selectedRowKeys.length} elemento selezionato` : ''}
                              </span>
                              <DropdownMenu
                                 type='primary'
                                 title='Azioni multiple'
                                 action={this.multipleActionHandler}
                                 values={this.state.multipleActionList}
                                 disabled={!hasSelected}
                                 loading={this.state.multipleActionLoader}
                              />
                           </Space> */}
                        </div>
                        <div className="col-sm-6">
                           <div className="float-right d-none d-md-block">
                              {/* <Button type="primary" loading={this.state.loader}>
                                    Mostra solo righe con differenze
                                 </Button> */}
                           </div>
                        </div>
                     </div>
                  </Col>
               </Row>

               <Row>
                  <Col span={24}>
                     <Table {...tableLayout}
                        columns={table_columns}
                        dataSource={this.state.itemList}
                        // rowSelection={rowSelection}
                        rowKey={(record) => (record.id)}
                        expandable={{
                           expandedRowRender: record => (
                              <Table
                                 size='small'
                                 columns={productsColumns}
                                 dataSource={record.order_lines}
                                 rowKey={(item) => (item.id)}
                                 pagination={false}
                              />
                           )
                        }}
                     />
                  </Col>
               </Row>
            </div>
         </div >
      );

      // const helpButton = {
      //    title: "Aiuto"
      // }

      // const buttons = [];
      // buttons.push(helpButton);

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(OrdersList);