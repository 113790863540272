import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Divider, Drawer, Form, Input, InputNumber, Button, Space, notification, Select, Radio, Typography } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons';

import languageManager from '../../../../services/languageManager';
import * as storeActions from '../store/actions/products';
import * as variationsActions from '../../variations/store/actions/variations';

class CreateVariationDrawer extends Component {
   state = {
      ...this.props,
      componentLoader: true,
      formLoader: false,
      formValues: {},
      parentValues: {},
      variationsList: [],
      variationValuesList: [],
      secondaryVariationValuesList: [],
      componentLanguage: this.props.authState.defaultLanguage
   }
   formRef = React.createRef();

   componentDidMount() {
      Promise.all([
         this.getVariationsList()
      ]).then(() => {
         this.setState({
            componentLoader: false
         });
      });
   }

   shouldComponentUpdate = (nextProps, nextState) => {
      // console.log('Component should update', nextProps, this.props, nextState, this.state)
      if (
         _.isEqual(nextProps.visible, this.props.visible)
         && _.isEqual(nextState, this.state)
      ) {
         // console.log('DO NOT UPDATE')
         return false
      }
      // console.log('DO UPDATE')
      return true
   }

   componentDidUpdate = (prevProps, prevState) => {
      console.log('Component did update visible', prevProps.visible, this.props.visible)
      if (
         !_.isNil(this.props.item)
         && this.formRef.current
         && !_.isEqual(prevProps.visible, this.props.visible)
      ) {
         console.log('Component did update item', this.props.item)
         this.setState({ parentValues: { ...this.props.parentValues } });
         Promise.all([
            this.getVariationValuesList(this.props.item.variation_id, 'variationValuesList'),
            this.getVariationValuesList(this.props.item.variation_id_secondary ?? false, 'secondaryVariationValuesList')
         ]).then(() => {
            this.formRef.current.setFieldsValue({ ...this.props.item })
         });
      } else if (
         !_.isNil(this.props.parentValues)
         && this.formRef.current
         && !_.isEqual(prevProps.visible, this.props.visible)
      ) {
         this.setState({ parentValues: { ...this.props.parentValues } });
      }
      // this.setState({ visible: this.props.visible })
   }

   getVariationsList = (current_variation = false) => {
      return variationsActions.getList()
         .then(response => {
            console.log(response);
            try {
               var newVariationValues = []
               if (current_variation) {
                  _.forEach(response.data, (el, i) => {
                     if (el.id === current_variation) {
                        newVariationValues = el.variation_values
                        return false
                     }
                  });
               }
               this.setState({ variationsList: response.data, variationValuesList: newVariationValues });
            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
            }
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   getVariationValuesList = (current_variation, output) => {
      if(current_variation) {
         var newVariationValues = []
         _.forEach(this.state.variationsList, (el, i) => {
            if (el.id === current_variation) {
               newVariationValues = el.variation_values
               return false
            }
         });
         var newState = {}
         newState[output] = newVariationValues
         this.setState(newState);
      }
   }

   handleVariationChange = (value) => {
      var newVariationValues = []
      _.forEach(this.state.variationsList, (el, i) => {
         if (el.id === value) {
            newVariationValues = el.variation_values
            return false
         }
      });
      this.formRef.current.setFieldsValue({ variation_value_id: null })
      this.setState({ variationValuesList: newVariationValues });
   }
   handleSecondaryVariationChange = (value) => {
      var newVariationValues = []
      _.forEach(this.state.secondaryVariationsList, (el, i) => {
         if (el.id === value) {
            newVariationValues = el.variation_values
            return false
         }
      });
      this.formRef.current.setFieldsValue({ variation_value_id_secondary: null })
      this.setState({ secondaryVariationsList: newVariationValues });
   }
   handlePriceOnRequestChange = (e) => {
      console.log(e.target.value)
      let newValues = { ...this.state.formValues }
      newValues.price_on_request = e.target.value
      this.setState({ formValues: newValues })
   }

   onFinish = values => {
      this.setState({ formLoader: true });
      values.visible = true;
      storeActions.createOrUpdateVariation(values, true)
         .then(response => {
            try {
               this.formRef.current.resetFields()
               this.setState({ formLoader: false });
               this.state.onSubmit(response.data)
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
                  description: languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               this.setState({ formLoader: false });
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
            }

         }).catch((e) => {
            this.setState({ formLoader: false });
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
         });
   };

   onClose = () => {
      this.setState({ variationValuesList: [] })
      this.formRef.current.resetFields()
      this.state.onClose()
   }

   render() {
      console.log('Create variation drawer', this.state)
      const { Text } = Typography;
      const { Option } = Select

      return (
         <Drawer
            title={!_.isNil(this.props.item) ? 'Modifica variante' : "Crea una nuova variante"}
            width={500}
            onClose={this.onClose}
            visible={this.props.visible}
            drawerStyle={{ paddingTop: 70 }}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
               <div className='float-right'>
                  <Space>
                     <Button onClick={this.onClose} disabled={this.state.formLoader}>Chiudi</Button>
                     <Button form="createVariationDrawer" type="primary" htmlType="submit" loading={this.state.formLoader}>Salva</Button>
                  </Space>
               </div>
            }
         >
            <Form layout="horizontal"
               ref={this.formRef}
               id="createVariationDrawer"
               onFinish={this.onFinish}
               onFinishFailed={this.onFinishFailed}>
               <Form.Item
                  label="Variante principale"
                  name="variation_id"
                  rules={[{ required: true, message: 'Scegli un valore' }]}
                  allowClear={true}
               >
                  <Select
                     showSearch
                     placeholder="Seleziona la variante principale"
                     optionFilterProp="children"
                     onChange={this.handleVariationChange}
                     allowClear={true}
                  >
                     {
                        this.state.variationsList.map((variation) => {
                           return <Option key={variation.id} value={variation.id}>{variation.name[this.state.componentLanguage]}</Option>
                        })
                     }
                  </Select>
               </Form.Item>
               <Form.Item
                  label="Valore variante"
                  name="variation_value_id"
                  rules={[{ required: true, message: 'Scegli un valore' }]}
               >
                  <Select
                     showSearch
                     placeholder="Seleziona il valore della variante"
                     optionFilterProp="children"
                     disabled={_.isEmpty(this.state.variationValuesList)}
                  >
                     {
                        this.state.variationValuesList.map((variationValue) => {
                           return <Option key={variationValue.id} value={variationValue.id}>{variationValue.name[this.state.componentLanguage]}</Option>
                        })
                     }
                  </Select>
               </Form.Item>
               <Form.Item
                  label="Variante secondaria"
                  name="variation_id_secondary"
               >
                  <Select
                     showSearch
                     placeholder="Seleziona la variante secondaria"
                     optionFilterProp="children"
                     onChange={this.handleSecondaryVariationChange}
                     allowClear={true}
                  >
                     {
                        this.state.variationsList.map((variation) => {
                           return <Option key={variation.id} value={variation.id}>{variation.name[this.state.componentLanguage]}</Option>
                        })
                     }
                  </Select>
               </Form.Item>
               <Form.Item
                  label="Valore variante"
                  name="variation_value_id_secondary"
               >
                  <Select
                     showSearch
                     placeholder="Seleziona il valore della variante"
                     optionFilterProp="children"
                     disabled={_.isEmpty(this.state.secondaryVariationValuesList)}
                     allowClear={true}
                  >
                     {
                        this.state.secondaryVariationValuesList.map((variationValue) => {
                           return <Option key={variationValue.id} value={variationValue.id}>{variationValue.name[this.state.componentLanguage]}</Option>
                        })
                     }
                  </Select>
               </Form.Item>
               <Form.Item
                  label="SKU"
                  name="sku"
                  rules={[{ required: true, message: 'Inserisci il codice SKU' }]}
               >
                  <Input />
               </Form.Item>
               <Form.Item
                  label="Codice fornitore"
                  name="supplier_sku"
                  rules={[{ message: 'Inserisci il codice del fornitore' }]}
               >
                  <Input />
               </Form.Item>
               <Divider />
               {
                  this.state.formValues.manage_quantity ? (
                     this.state.formValues.use_stocks ? '' : (
                        <Form.Item
                           label="Quantità disponibile"
                           name="actual_quantity"
                           rules={[]}
                        >
                           <InputNumber step={1} />
                        </Form.Item>
                     )
                  ) : (
                     <Form.Item
                        label="Disponibilità"
                        name="always_available"
                     >
                        <Radio.Group>
                           <Radio.Button value={1}>Disponibile</Radio.Button>
                           <Radio.Button value={0}>Non disponibile</Radio.Button>
                        </Radio.Group>
                     </Form.Item>
                  )
               }
               <Divider />
               {
                  this.state.parentValues.price_on_variation ? ([
                     // <Form.Item
                     //    label="Prezzo su richiesta"
                     //    key="price_on_request"
                     //    name="price_on_request">
                     //    <Radio.Group
                     //       onChange={this.handlePriceOnRequestChange}>
                     //       <Radio.Button value={false}>Prezzo indicato</Radio.Button>
                     //       <Radio.Button value={1}>Prezzo su richiesta</Radio.Button>
                     //    </Radio.Group>
                     // </Form.Item>,
                     !this.state.formValues.price_on_request ? ([
                        <Form.Item
                           label="Prezzo di listino (tasse escluse)"
                           name="price_retail"
                           key="price_retail"
                        >
                           <InputNumber
                              decimalSeparator=','
                              precision={2}
                              onChange={this.onPriceRetailChange}
                           />
                        </Form.Item>,
                        <Form.Item
                           label="Prezzo di acquisto (tasse escluse)"
                           name="price_buy"
                           key="price_buy"
                        >
                           <InputNumber
                              decimalSeparator=','
                              precision={2}
                              onChange={this.onPriceBuyChange}
                           />
                        </Form.Item>
                     ]) : null
                  ]) : <Text strong>Prezzo su prodotto padre</Text>
               }
               <Divider key='shipment_divider' />
               <Form.Item
                  key='weight'
                  label="Peso (KG)"
                  name="weight"
                  rules={[]}
               >
                  <InputNumber
                     decimalSeparator=','
                     precision={2}
                  />
               </Form.Item>
               <Form.Item
                  key='length'
                  label="Lunghezza (m)"
                  name="length"
                  rules={[]}
               >
                  <InputNumber
                     decimalSeparator=','
                     precision={6}
                  />
               </Form.Item>
               <Form.Item
                  key='height'
                  label="Altezza (m)"
                  name="height"
                  rules={[]}
               >
                  <InputNumber
                     decimalSeparator=','
                     precision={6}
                  />
               </Form.Item>
               <Form.Item
                  key='width'
                  label="Profondità (m)"
                  name="width"
                  rules={[]}
               >
                  <InputNumber
                     decimalSeparator=','
                     precision={6}
                  />
               </Form.Item>
               <Form.Item name="id" noStyle><Input type="hidden" /></Form.Item>
               <Form.Item name="parent_id" initialValue={this.props.itemId} noStyle><Input type="hidden" /></Form.Item>
               <Form.Item name="language" initialValue={this.state.componentLanguage} noStyle><Input type="hidden" /></Form.Item>
            </Form>
         </Drawer>
      )
   }
}

function mapStateToProps(state) {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
}

export default connect(mapStateToProps)(CreateVariationDrawer)