import React, { Component } from 'react'
import _ from 'lodash'
import { TreeSelect } from 'antd'

class SelectCategory extends Component {
   state = {
      ...this.props,
   }

   componentDidUpdate = (prevProps, prevState) => {
      if (!_.isEqual(prevState.categoriesTree, this.props.categoriesTree)) {
         this.setState({ ...this.props })
      }
   }

   onChange = value => {
      console.log('onChange ', value);
      this.setState({ initialValue: value })
      this.state.onChange(value)
   };

   render() {
      console.log('Select category', this.state)
      const { SHOW_PARENT } = TreeSelect;
      const tProps = {
         treeData: this.state.categoriesTree,
         // value: this.state.value,
         // onChange: this.onChange,
         treeCheckable: true,
         treeDefaultExpandAll: true,
         showCheckedStrategy: SHOW_PARENT,
         placeholder: 'Seleziona la categoria',
         value: this.state.initialValue,
         onChange: this.onChange,
         style: {
            width: '100%',
         },
         disabled: this.state.disabled,
      }
      return (
         <TreeSelect {...tProps} />
      );
   }
}

export default SelectCategory;