import CustomersApi from '../../../../../api/CustomersApi';
import _ from 'lodash';

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getList(parentId = null) {
   return CustomersApi.getList(parentId)
      .then(response => {
         if (response.data.success === true) {
            response.data.data.forEach(el => {
               el.key = el.id;
            });
            console.log(response);
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getListExtended(page, pageSize, filters) {
   return CustomersApi.getListExtended(page, pageSize, filters)
      .then(response => {
         if (response.data.success === true) {
            response.data.data.data.forEach(el => {
               el.key = el.id;
            });
            console.log(response);
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getItem(itemId) {
   return CustomersApi.getItem(itemId)
      .then(response => {
         if (response.data.success === true) {
            console.log(response);
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function getComplements() {
   return CustomersApi.getComplements()
      .then(response => {
         console.log(response);
         const responseData = {}
         _.forEach(response, (el, i) => {
            if (el.data.success !== true) {
               const error = el.data.error ? el.data.error : 'Server error'
               throw new Error(error)
            }
            responseData[i] = el.data.data;
         });
         return responseData;
      })
      .catch(error => {
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function createOrUpdate(itemData) {
   console.log(itemData);
   return CustomersApi.createOrUpdate(itemData)
      .then(response => {
         if (response.data.success === true) {
            console.log(response);
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function deleteItem(itemId, return_list = false) {
   return CustomersApi.deleteItem(itemId, return_list)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function importFromCSV(formData) {
   return CustomersApi.importFromCSV(formData)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else {
            throw new Error(response.data.exception ? response.data.exception :
               response.data.error ? response.data.error : 'serverError');
         }
      })
      .catch(error => {
         throw new Error(error.data.exception ? error.data.exception :
            error.data.error ? error.data.error : 'serverError');
      });
}
export function exportCSV(filename, customerIds) {
   return CustomersApi.exportCSV(filename, customerIds)
      .then(response => {
         let blob = new Blob(["\uFEFF" + response.data], { type: 'application/*' })
         let link = document.createElement('a')
         link.href = window.URL.createObjectURL(blob)
         link.download = filename;
         link.click();
      })
      .catch(error => {
         throw error;
      });
}